import translation from './translation'

export default (page) => {
    if (!('lang' in localStorage)) {
        const navigatorLanguage = navigator
            .language
            .replace(/^(\w+)(-.*)$/, '$1')

        if (
               navigatorLanguage === 'pt'
            || navigatorLanguage === 'es'
        ) {
            localStorage.setItem('lang', navigatorLanguage)
        } else {
            localStorage.setItem('lang', 'pt')
        }
    }

    let languageCode = localStorage.getItem('lang')

    if (languageCode !== 'pt' && languageCode !== 'es') {
        localStorage.setItem('lang', 'pt')
        languageCode = 'pt'
    }

    return (text) => {
        return translation[page][text][languageCode]
    }
}
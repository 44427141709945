import { createGlobalStyle } from 'styled-components'
import { DomainEnv } from './domainEnv'

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: Roboto, Helvetica, Verdana, sans-serif;
        font-weight: 400;

        ::selection {
            background-color: #e74c3c;
            color: white;
        }

        /**
         * Evitar efeito esquisito durante o click no mobile.
         */
        @media (hover: none) and (pointer: coarse) {
            cursor: default !important;
        }

        /**
         * Tema.
         */
        --theme-primary-color: ${DomainEnv.themePrimaryColor};
        --theme-primary-color-light: ${DomainEnv.themePrimaryColorLight};
        --theme-highly-light-gray: ${DomainEnv.themeHighlyLightGray};
    }

    html {
        font-size: 14px;
        color: #333;
    }

    html,
    body,
    #root {
        height: 100%;
    }

    body {
        background-color: white;
    }

    /* Configurações para o scroll. */
    @media only screen and (min-width: 500px) {
        ::-webkit-scrollbar {
            width: 13px;
            background-color: #ddd;
        }
        ::-webkit-scrollbar-track {
            border-radius: 10px;
        }
        ::-webkit-scrollbar-thumb {
            border: solid 2px #ddd;
            background-color: #aaa;
        }
        ::-webkit-scrollbar-thumb:hover {
            background-color: #999;
        }
    }
`
import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import { remove as removeDiacritics } from 'diacritics'
import axios from 'axios'

// Configurações.
import api from '../../api'

// Componentes.
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Content from '../../components/Content'
import UserDataForm from '../../components/UserDataForm'

// Utilitários.
import getFirstName from '../../utils/getFirstName'
import dbDateToHuman from '../../utils/dbDateToHuman'
import objPropsToString from '../../utils/objPropsToString'
import { cm2m } from '../../utils/convertLength'

// i18n
import i18n from '../../i18n/i18n'

const $ = i18n('Account')

export default class extends Component {
    state = {
        alertIsOpen: false,
        alertText: '',
        data: {}
    }

    componentWillMount() {
        const accessToken = window.localStorage.getItem('access_token')
    
        // Se o usuário não tiver logado vai voltar para a página de login.
        if (!accessToken) {
            this.props.history.push('/exams')
        }

        // Obter dados do paciente.
        axios
            .get(`${api.baseUrl}/pacientes`, {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })
            .then(({ data }) => {

                // DADOS QUE SERÃO APRESENTADOS AO USUÁRIO.

                data['dt_nascimento'] = dbDateToHuman(data['dt_nascimento'])
                data['qt_altura_cm'] = cm2m(data['qt_altura_cm'])

                this.setState({
                    data: objPropsToString(data),
                    userName: getFirstName(data.nm_pessoa_fisica)
                })
            })
            .catch(({ response }) => {
                if (!response)  return

                // Se não autorizado.
                if (response.status === 401 || response.status === 500) {

                    // Deslogar usuário.
                    return localStorage.removeItem('access_token')
                }
            })
    }

    alertOnOkay = false
    alertOnCancel = false
 
    closeAlert = () => {
        this.setState({
            alertIsOpen: false,
            alertText: ''
        })

        this.alertOnOkay = false
        this.alertOnCancel = false
    }

    setAlert = (text, onOkay, onCancel) => {
        this.setState({
            alertIsOpen: true,
            alertText: text
        })

        this.alertOnOkay = () => {
            (onOkay || (() => {}))()
            this.closeAlert()
        }

        if (onCancel) {
            this.alertOnCancel = () => {
                (onCancel || (() => {}))()
                this.closeAlert()
            }
        } 
    }

    handleInputChange = (key) => {
        return ({ target: { value } }) => {
            this.setState({
                data: {
                    ...this.state.data,
                    [key]: removeDiacritics(value).toUpperCase()
                }
            })
        }
    }

    onCancel = () => {
        this.setAlert($('alertConfirmacao'), () => {

            // Se for okay.
            this.props.history.push('/exams')
        }, () => {
            // Não faz nada.
        })
    }

    onContinue = () => {
        setTimeout(() => {
            this.props.history.push('/exams')
        }, 0)
    }

    render = () => {
        return (
            <Fragment>
                <Alert
                    isOpen={this.state.alertIsOpen}
                    handleClose={this.closeAlert}
                    handleOkay={() => this.alertOnOkay()}
                    handleCancel={this.alertOnCancel && (() => this.alertOnCancel())}
                    text={this.state.alertText}
                />

                <Content
                    headerTitle={$('headerTitulo')(this.state.userName)}
                    headerDescription={$('headerDescricao')}
                    isLoading={Object.keys(this.state.data).length === 0}
                >
                    <UserDataForm
                        onSubmit={this.onContinue}
                        values={this.state.data}
                        handleInputChange={this.handleInputChange}
                    />

                    <Actions>
                        <div>
                            <Button isInline isSecondary onClick={this.onCancel}>
                                {$('btnCancelar')}
                            </Button>
                        </div>
                        <div>
                            <Button isInline onClick={this.onContinue}>
                                {$('btnContinuar')} <i className="fas fa-arrow-right" />
                            </Button>
                        </div>
                    </Actions>
                </Content>
            </Fragment>
        )
    }
}

const Actions = styled.div`
    display: flex;
    padding-top: 15px;
    justify-content: space-between;

    @media only screen and (max-width: 500px) {
        padding-top: 0;
    }
`
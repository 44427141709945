import React from 'react'
import styled from 'styled-components'

// Componentes.
import { IconUsersCog, IconSignOut } from '../../components/Icons'

// i18n
import i18n from '../../i18n/i18n'
import { DomainEnv } from '../../domainEnv'

const $ = i18n('Exams')

export default ({ name, logout, seeUserData }) => (
    <Header>
        <div>
            <Logo src={DomainEnv.logo} />
        </div>
        <Menu>
            <div>
                <Option onClick={seeUserData}>
                    <IconUsersCog />
                    <OptionLabel>{$('btnMeusDados')}</OptionLabel>
                </Option>
            </div>
            <div>
                <Option onClick={logout}>
                    <IconSignOut />
                    <OptionLabel>{$('btnSair')}</OptionLabel>
                </Option>
            </div>
        </Menu>
    </Header>
)

const Header = styled.header`
    display: flex;
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
    justify-content: space-between;
    align-items: center;
`
const Logo = styled.img`
    width: 150px;

    @media only screen and (max-width: 500px) {
        width: 120px;
    }
`
const Menu = styled.div`
    display: flex;
    align-items: center;

    div:not(:last-child) {
        margin-right: 5px;
    }
`
const Option = styled.button`
    display: inline-block;
    padding: 8px 10px;
    border-radius: 3px;
    font-size: 1.15em;
    border: solid 1px #ddd;
    text-decoration: none;
    color: #333;
    cursor: pointer;
    outline: none;
    background-color: white;

    @media only screen and (max-width: 500px) {
        padding: 8px 12px;
    }

    :hover {
        background-color: #f1f1f1;
    }
    :active {
        background-color: #f5f5f5;
        color: #333;
    }
`
const OptionLabel = styled.span`
    padding-left: 5px;

    @media only screen and (max-width: 500px) {
      display: none;
    }
`
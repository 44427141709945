import React from 'react'
import styled from 'styled-components'

export default ({
    width = 100,
    label = false,
    color = 'black'
}) => {
    return (
        <Container>
            <Spinner width={width} color={color} />

            {label && (
                <Label>
                    {label}
                </Label>
            )}
        </Container>
    )
}

const Container = styled.div`
    display: inline-block;
    text-align: center;
`
const Spinner = styled.div`
    display: inline-block;
    width: ${props => props.width}px;
    height: ${props => props.width}px;
    animation: spin .4s linear infinite;
    border-radius: 100%;
    border: solid ${props => props.width / (0.25 * props.width)}px ${props => props.color};
    border-right-color: transparent;
    background-color: transparent;

    @keyframes spin {
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    }
`
const Label = styled.p`
    margin-top: 10px;
    color: gray;
`
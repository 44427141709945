import React from 'react'
import styled, { css } from 'styled-components'

// Componentes.
import Input from './Input'
import SelectGender from './SelectGender'
import SelectStates from './SelectStates'

// Utilitários.
import cpf from 'cpf'

// i18n
import i18n from '../i18n/i18n'

const $ = i18n('components')

export default ({
    onSubmit,
    values,
    handleInputChange
}) => (
    <form onSubmit={onSubmit}>
        <Fields>
            <FlexContainer>
                <InputGroup fillSpace>
                    <InputLabel htmlFor="val-nm_pessoa_fisica" required>
                        {$('nome_completo')}
                    </InputLabel>
                    <Input
                        id="val-nm_pessoa_fisica"
                        isSmall
                        disabled
                        placeholder={$('nome_completo')}
                        autocomplete="name"
                        value={values.nm_pessoa_fisica || ''}
                        onChange={handleInputChange('nm_pessoa_fisica')}
                    />
                </InputGroup>
                <InputGroup>
                    <InputLabel>
                        ID
                    </InputLabel>
                    <Input
                        isSmall
                        disabled
                        type="number"
                        placeholder="ID"
                        value={values.cd_pessoa_fisica || ''}
                    />
                </InputGroup>
            </FlexContainer>
            <FlexContainer>
                <InputGroup fillSpace>
                    <InputLabel htmlFor="val-ie_sexo" required>
                        {$('genero')}
                    </InputLabel>
                    <SelectGender
                        id="val-ie_sexo"
                        isSmall
                        disabled
                        value={values.ie_sexo || ''}
                        onChange={handleInputChange('ie_sexo')}
                        placeholder={$('selecione_genero')}
                    />
                </InputGroup>
                <MobileInlineContainer>
                    <InputGroup>
                        <InputLabel htmlFor="val-qt_peso">
                            {$('peso')} (kg)
                        </InputLabel>
                        <Input
                            id="val-qt_peso"
                            type="number"
                            isSmall
                            disabled
                            placeholder={$('peso')}
                            value={values.qt_peso || ''}
                            onChange={handleInputChange('qt_peso')}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputLabel htmlFor="val-qt_altura_cm">
                            {$('altura')} (m)
                        </InputLabel>
                        <Input
                            id="val-qt_altura_cm"
                            type="number"
                            isSmall
                            disabled
                            placeholder={$('altura')}
                            value={values.qt_altura_cm || ''}
                            onChange={handleInputChange('qt_altura_cm')}
                        />
                    </InputGroup>
                </MobileInlineContainer>
            </FlexContainer>
            <FlexContainer>
                <MobileInlineContainer>
                    <InputGroup fillSpace>
                        <InputLabel>
                            CPF
                        </InputLabel>
                        <Input
                            isSmall
                            disabled
                            placeholder="CPF"
                            value={
                                values.nr_cpf
                                    ? cpf.format(values.nr_cpf)
                                    : ''
                            }
                        />
                    </InputGroup>
                    <InputGroup fillSpace>
                        <InputLabel htmlFor="val-nr_identidade" required>
                            RG
                        </InputLabel>
                        <Input
                            id="val-nr_identidade"
                            type="number"
                            isSmall
                            disabled
                            placeholder="RG"
                            value={values.nr_identidade || ''}
                            onChange={handleInputChange('nr_identidade')}
                        />
                    </InputGroup>
                </MobileInlineContainer>
                <InputGroup fillSpace>
                    <InputLabel required>
                        {$('data_de_nascimento')}
                    </InputLabel>
                    <Input
                        type="text"
                        isSmall
                        disabled
                        placeholder={$('data_de_nascimento')}
                        value={values.dt_nascimento || ''}
                    />
                </InputGroup>
            </FlexContainer>
            <FlexContainer>
                <MobileInlineContainer>
                    <InputGroup widthForMobile={100}>
                        <InputLabel htmlFor="val-nr_ddd_celular">
                            DDD
                        </InputLabel>
                        <Input
                            id="val-nr_ddd_celular"
                            type="number"
                            style={{ width: 100 }}
                            isSmall
                            disabled
                            placeholder="DDD"
                            value={values.nr_ddd_celular || ''}
                            onChange={handleInputChange('nr_ddd_celular')}
                        />
                    </InputGroup>
                    <InputGroup fillSpace>
                        <InputLabel htmlFor="val-nr_telefone_celular">
                            {$('telefone')}
                        </InputLabel>
                        <Input
                            id="val-nr_telefone_celular"
                            type="tel"
                            isSmall
                            disabled
                            placeholder={$('telefone')}
                            autocomplete="tel"
                            value={values.nr_telefone_celular || ''}
                            onChange={handleInputChange('nr_telefone_celular')}
                        />
                    </InputGroup>
                </MobileInlineContainer>
                <InputGroup fillSpace lowercase>
                    <InputLabel htmlFor="val-ds_email">
                        E-mail
                    </InputLabel>
                    <Input
                        id="val-ds_email"
                        type="email"
                        isSmall
                        disabled
                        placeholder="E-mail"
                        autocomplete="email"
                        value={values.ds_email || ''}
                        onChange={handleInputChange('ds_email')}
                    />
                </InputGroup>
            </FlexContainer>
            <FlexContainer>
                <InputGroup fillSpace>
                    <InputLabel htmlFor="val-ds_bairro">
                        {$('bairro')}
                    </InputLabel>
                    <Input
                        id="val-ds_bairro"
                        isSmall
                        disabled
                        placeholder={$('bairro')}
                        value={values.ds_bairro || ''}
                        onChange={handleInputChange('ds_bairro')}
                    />
                </InputGroup>
                <MobileInlineContainer>
                    <InputGroup>
                        <InputLabel htmlFor="val-ds_endereco">
                            {$('endereco')}
                        </InputLabel>
                        <Input
                            id="val-ds_endereco"
                            isSmall
                            disabled
                            placeholder="Endereço"
                            autocomplete="shipping street-address"
                            value={values.ds_endereco || ''}
                            onChange={handleInputChange('ds_endereco')}
                        />
                    </InputGroup>
                    <InputGroup widthForMobile={120}>
                        <InputLabel htmlFor="val-nr_endereco">
                            {$('numero')}
                        </InputLabel>
                        <Input
                            id="val-nr_endereco"
                            type="number"
                            style={{ width: 150 }}
                            isSmall
                            disabled
                            placeholder={$('numero')}
                            value={values.nr_endereco || ''}
                            onChange={handleInputChange('nr_endereco')}
                        />
                    </InputGroup>
                </MobileInlineContainer>
            </FlexContainer>
            <FlexContainer htmlFor="val-cd_cep">
                <InputGroup>
                    <InputLabel>CEP</InputLabel>
                    <Input
                        id="val-cd_cep"
                        isSmall
                        disabled
                        autocomplete="shipping postal-code"
                        placeholder="CEP"
                        value={values.cd_cep || ''}
                        onChange={handleInputChange('cd_cep')}
                    />
                </InputGroup>
                <InputGroup fillSpace>
                    <InputLabel htmlFor="val-ds_municipio" required>
                        {$('cidade')}
                    </InputLabel>
                    <Input
                        id="val-ds_municipio"
                        isSmall
                        disabled
                        placeholder={$('cidade')}
                        autocomplete="shipping locality"
                        value={values.ds_municipio || ''}
                        onChange={handleInputChange('ds_municipio')}
                    />
                </InputGroup>
                <InputGroup fillSpace>
                    <InputLabel htmlFor="val-sg_estado" required>
                        {$('estado')}
                    </InputLabel>
                    <SelectStates
                        id="val-sg_estado"
                        isSmall
                        disabled
                        autocomplete="shipping region"
                        value={values.sg_estado || ''}
                        onChange={handleInputChange('sg_estado')}
                        placeholder={$('selecione_estado')}
                    />
                </InputGroup>
            </FlexContainer>
        </Fields>
    </form>
)

const Fields = styled.div`
    padding-bottom: 15px;
    border-bottom: solid 1px #eee;

    @media only screen and (max-width: 500px) {
        border-bottom: none;
        padding-bottom: 10px;
    }
`
const FlexContainer = styled.div`
    display: flex;
    margin-bottom: 20px;

    div:not(:last-child) {
        margin-right: 10px;
    }

    @media only screen and (max-width: 500px) {
        display: block;
        margin-bottom: 0;
        
        div {
            margin-right: 0 !important;
        }
        input {
            width: 100% !important;
        }
    }
`
const MobileInlineContainer = styled.div`
    display: flex;
    flex: 1;

    @media only screen and (max-width: 500px) {
        div {
            flex: 1;
        }
        div:first-child {
            padding-right: 5px !important;
        }
        div:last-child {
            padding-left: 5px !important;
        }
    }
`
const InputGroup = styled.div`
    display: block;

    input {
        text-transform: uppercase;
    }
    ${props => props.lowercase && css`
        input {
            text-transform: lowercase !important;
        }
    `}
    ${props => props.fillSpace && css`
        flex: 1;

        @media only screen and (max-width: 500px) {
            flex: unset;
        }
    `}
    @media only screen and (max-width: 500px) {
        padding-bottom: 12.5px;
    }
    @media only screen and (max-width: 500px) {
        ${props => props.widthForMobile && css`
            max-width: ${props.widthForMobile}px !important;
        `}
    }
`
const InputLabel = styled.label`
    display: block;
    margin-bottom: 5px;
    color: #333;

    ${props => props.isRequired && css`
        ::after {
            content: "*";
            color: red;
        }
    `}
`
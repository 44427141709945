const DEV_DATA = {
    baseUrl: 'http://api.portal.cerdil.com.br',
    urlShortener: 'http://v.cerdil.com.br',
}
const PROD_DATA = {
    baseUrl: 'https://api.portal.cerdil.com.br',
    urlShortener: 'https://v.cerdil.com.br',
}

if(window.location.host === 'portal.simdiagnostico.com.br') {
    PROD_DATA.urlShortener = 'https://v.simdiagnostico.com.br';
}

// Se estiver no modo desenvolvimento.
export default process.env.NODE_ENV === 'development'
    ? DEV_DATA
    : PROD_DATA
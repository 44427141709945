import React, { Fragment, Component } from 'react'
import axios from 'axios'

// Configurações.
import api from '../../api'

// Componentes.
import OneInputForm from './OneInputForm'

// Utilitários.
import cpf from 'cpf'
import dbDateToHuman from '../../utils/dbDateToHuman'
import { cm2m } from '../../utils/convertLength'

// i18n
import i18n from '../../i18n/i18n'

const $ = i18n('Login')

export default class extends Component {
    state = {
        currentForm: 'login',
        buttonIsDisabled: false,
        values: {
            login: '',
            password: '',
            confirmation: ''
        }
    }

    handleInputChange = (formType) => ({ target: { value } }) => {
        this.setState({
            values: {
                ...this.state.values,
                [formType]: value
            }
        })
    }

    changeCurrentForm = (newForm) => {

        // Se for um tipo de formulário válido.
        if (
               newForm !== 'login'
            && newForm !== 'password'
            && newForm !== 'confirmation'
        ) {
            return
        }

        this.setState({
            currentForm: newForm
        })
    }

    enableButton = () => {
        this.setState({
            buttonIsDisabled: false
        })
    }

    disableButton = () => {
        this.setState({
            buttonIsDisabled: true
        })
    }

    goToLoginPage = () => {
        this.setState({
            currentForm: 'login',
            values: {
                login: this.state.values.login,
                password: '',
                confirmation: ''
            }
        })
    }

    onSubmit = (type) => {
        let {
            login,
            password,
            confirmation: birthdate
        } = this.state.values

        // Limpa número (para remover formatações do CPF)
        login = login.replace(/\D/g, '')

        // Deixa data no formato: yyyy-mm-dd
        birthdate = birthdate.split('-').reverse().join('-')

        return () => {
            this.disableButton()

            switch (type) {

                case 'login': {
                    if (!login) return this.enableButton()

                    // Se for por CPF
                    if (login.length > 8 && !cpf.isValid(login)) {
                        this.props.setAlert($('alertCpfInvalido'))
                        return this.enableButton()
                    }

                    // Verificar existência do usuário.
                    axios
                        .get(`${api.baseUrl}/usuarios/login/${login}`)
                        .then(({ data }) => {
                            if (!data) {
                                return
                            }

                            if (data.status) this.changeCurrentForm('password')
                            else this.changeCurrentForm('confirmation')

                            this.enableButton()
                        })

                    break
                }

                case 'password': {
                    if (!password) return this.enableButton()

                    // Fazer login
                    axios
                        .post(`${api.baseUrl}/login`, {
                            login,
                            password
                        })
                        .then(({ data: { access_token, patient_id } }) => {
                            window.localStorage.setItem('access_token', access_token)

                            this.props.goToPage(`/exams`)
                            this.enableButton()
                        })
                        .catch(() => {
                            this.props.setAlert($('alertSenhaInvalida'))
                            this.enableButton()
                        })

                    break
                }

                case 'confirmation': {
                    if (!login) return this.enableButton()

                    // Acessar dados do usuário para fazer confirmação.
                    axios
                        .get(`${api.baseUrl}/pacientes/login/${login}/dt_nascimento/${birthdate}`)
                        .then(({ data }) => {

                            // DADOS QUE SERÃO APRESENTADOS AO USUÁRIO.

                            data['dt_nascimento'] = dbDateToHuman(data['dt_nascimento'])
                            data['qt_altura_cm'] = cm2m(data['qt_altura_cm'])

                            window.sessionStorage.clear()
                            window.sessionStorage.setItem('_userdata', JSON.stringify(data))

                            this.props.goToPage('/confirmation')
                            this.enableButton()
                        })
                        .catch((data) => {
                            this.props.setAlert($('alertPacienteNaoEncontrado'))
                            this.enableButton()
                        })

                    break
                }

                // no default
            }
        }
    }

    render = () => {
        switch (this.state.currentForm) {

            case 'login':
                return (
                    <OneInputForm
                        type={this.state.currentForm}
                        hasAnimation={false}
                        onSubmit={this.onSubmit('login')}
                        textBig={$('headerTitleLogin')}
                        textDescription={$('headerDescriptionLogin')}
                        inputValue={this.state.values.login}
                        inputValueOnChange={this.handleInputChange('login')}
                        inputPlaceholder={$('inputPlaceholderLogin')}
                        inputType="number"
                        buttonIsDisabled={this.state.buttonIsDisabled}
                        buttonLabel={
                            <Fragment>
                                {$('btnLogin')} <i className="fas fa-arrow-right" />
                            </Fragment>
                        }
                    />
                )

            case 'password':
                return (
                    <OneInputForm
                        onClickToBack={this.goToLoginPage}
                        type={this.state.currentForm}
                        onSubmit={this.onSubmit('password')}
                        textBig={$('headerTitlePassword')}
                        textDescription={$('headerDescriptionPassword')}
                        inputType="password"
                        inputValue={this.state.values.password}
                        inputValueOnChange={this.handleInputChange('password')}
                        inputPlaceholder={$('inputPlaceholderPassword')}
                        buttonIsDisabled={this.state.buttonIsDisabled}
                        buttonLabel={
                            <Fragment>
                                {$('btnPassword')} <i className="fas fa-arrow-right" />
                            </Fragment>
                        }
                    />
                )

            case 'confirmation':
                return (
                    <OneInputForm
                        onClickToBack={this.goToLoginPage}
                        type={this.state.currentForm}
                        onSubmit={this.onSubmit('confirmation')}
                        textBig={$('headerTitleConfirmation')}
                        textDescription={$('headerDescriptionConfirmation')}
                        inputValue={this.state.values.confirmation}
                        inputValueOnChange={this.handleInputChange('confirmation')}
                        inputPlaceholder={$('inputPlaceholderConfirmation')}
                        buttonIsDisabled={this.state.buttonIsDisabled}
                        buttonLabel={
                            <Fragment>
                                {$('btnConfirmation')} <i className="fas fa-arrow-right" />
                            </Fragment>
                        }
                    />
                )

            default:
                return (
                    <span>
                        {$('alertErroInterno')}
                    </span>
                )
        }
    }
}

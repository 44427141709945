import SvgIconArrowLeft from '../icons/SvgIconArrowLeft'
import SvgIconArrowUp from '../icons/SvgIconArrowUp'
import SvgIconCalendarAlt from '../icons/SvgIconCalendarAlt'
import SvgIconCheckSolid from '../icons/SvgIconCheckSolid'
import SvgIconCopy from '../icons/SvgIconCopy'
import SvgIconDoctor from '../icons/SvgIconDoctor'
import SvgIconEye from '../icons/SvgIconEye'
import SvgIconEyeSlash from '../icons/SvgIconEyeSlash'
import SvgIconFileAlt from '../icons/SvgIconFileAlt'
import SvgIconGlobe from '../icons/SvgIconGlobe'
import SvgIconMapMarkerAlt from '../icons/SvgIconMapMarkerAlt'
import SvgIconPhone from '../icons/SvgIconPhone'
import SvgIconQuestionCircleSolid from '../icons/SvgIconQuestionCircleSolid'
import SvgIconShareAltSolid from '../icons/SvgIconShareAltSolid'
import SvgIconSignOut from '../icons/SvgIconSignOut'
import SvgIconUsersCog from '../icons/SvgIconUsersCog'
import SvgIconWhatsapp from '../icons/SvgIconWhatsapp'

export const IconArrowLeft = () => SvgIconArrowLeft
export const IconArrowUp = () => SvgIconArrowUp
export const IconCalendarAlt = () => SvgIconCalendarAlt
export const IconCheckSolid = () => SvgIconCheckSolid
export const IconCopy = () => SvgIconCopy
export const IconDoctor = () => SvgIconDoctor
export const IconEye = () => SvgIconEye
export const IconEyeSlash = () => SvgIconEyeSlash
export const IconFileAlt = () => SvgIconFileAlt
export const IconGlobe = () => SvgIconGlobe
export const IconMapMarkerAlt = () => SvgIconMapMarkerAlt
export const IconPhone = () => SvgIconPhone
export const IconQuestionCircleSolid = () => SvgIconQuestionCircleSolid
export const IconShareAltSolid = () => SvgIconShareAltSolid
export const IconSignOut = () => SvgIconSignOut
export const IconUsersCog = () => SvgIconUsersCog
export const IconWhatsapp = () => SvgIconWhatsapp
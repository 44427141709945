import React from 'react'
import styled, { css } from 'styled-components'

// Estilo base.
import InputContainer from './InputContainer'

export default (props) => (
    <StyledComponent as="input" {...props} />
)

const StyledComponent = styled(InputContainer)`
    color: black;
    border: solid 1px var(--theme-primary-color-light);
    background-color: white;

    :disabled {
        background-color: #eee;
    }
    :focus {
        border-color: var(--theme-primary-color);
    }

    ${props => props.isValid !== undefined && !props.isValid && css`
        color: darkred;
        background-color: #fddadf;
    `}
    ${props => props.hideSpinner && css`
        &::-webkit-inner-spin-button, 
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    `}
`
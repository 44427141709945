import React from 'react'
import styled from 'styled-components'
import { DomainEnv } from '../../domainEnv'
export default () => (
    <Header>
        <Logo src={DomainEnv.logo} />
    </Header>
)

const Header = styled.header`
    padding-top: 25px;
    display: block;
    text-align: center;
`
const Logo = styled.img`
    display: inline-block;
    width:  150px;
`
import React from 'react'

// components
import Input from './Input'

export default function ({ ...props }) {
    const VALID_DATE_RE = /^\d{1,2}(-(\d{1,2}(-(\d{1,4})?)?)?)?$/
    const GENERAL_DATA_RE = /^(\d{2}).(\d{2}).(\d{4})$/

    const onChange = (event) => {
        const { value } = event.target
        const isAdding = value.length > props.value.length
        const wroteDayOrMonth = /^\d{2}(-\d{2})?$/.test(value)

        if (props.value.trim() === '' && GENERAL_DATA_RE.test(value.trim())) {
            const [ , dd, mm, yyyy ] = value.match(GENERAL_DATA_RE)
            const normalDate = `${dd}-${mm}-${yyyy}`

            event.target.value = normalDate

            props.onChange(event)
            return
        }

        if (VALID_DATE_RE.test(value) || !value) {
            if (isAdding && wroteDayOrMonth) {
                event.target.value = `${value}-`
            }

            props.onChange(event)
            return
        }
    }

    return (
        <Input
            {...props}
            type="text"
            onChange={onChange}
            inputMode="numeric"
            pattern="\d{2}-\d{2}-\d{4}"
        />
    )
}
import React, { Fragment, Component, useState, useEffect } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import Clipboard from 'react-clipboard.js'

// Componentes.
import ExamsList from './ExamsList'
import Header from './Header'
import PopUp from './PopUp'
import Alert from '../../components/Alert'
import Content from '../../components/Content'
import Input from '../../components/Input'
import { IconCopy, IconArrowUp, IconShareAltSolid } from '../../components/Icons'

// Dados.
import api from '../../api'

// Utilitários;
import getFirstName from '../../utils/getFirstName'
import getLocalById from '../../utils/getLocalById'
import prettyDbDate from '../../utils/prettyDbDate'

// i18n
import i18n from '../../i18n/i18n'

const $ = i18n('Exams')

// Constantes.
const EXAMS_PER_PAGE = 8

export default class extends Component {
  state = {
    patientId: window.localStorage.getItem('patient_id'),
    accessToken: window.localStorage.getItem('access_token'),
    patientInformations: {},
    filterValue: '',
    exams: [],
    isLoadingExams: true,
    currentPage: 1,
    statusMessage: false,

    // PopUp do exame.
    popUpIsOpened: false,
    popUpUrl: '',

    alertIsOpen: false,
    alertText: '',

    // Lista de itens desabilitados baseado no NA.
    disabledSeeExamButton: null,
    disabledShareButton: null,

    isShowingCopiedTip: false,
    showBackToTopButton: false,

    alertShowActions: true
  }

  filterTimeout = 0
  containerRef = React.createRef()
  searchInputRef = React.createRef()

  alertOnOkay = false
  alertOnCancel = false

  componentWillMount = () => {

    // Se usuário não estiver logado, redireciona para a página de login.
    if (!('access_token' in localStorage)) {
      this.props.history.push('/')
    }
  }

  componentDidMount = () => {
    this.setStateForPatientInformations()
    this.setStateForExams()

    this
      .containerRef
      .current
      .parentElement
      .addEventListener('scroll', this.checkScroll)

    window
      .addEventListener('keydown', this.onPressBar)
  }

  componentWillUnmount = () => {
    this
      .containerRef
      .current
      .parentElement
      .removeEventListener('scroll', this.checkScroll)

    window
      .removeEventListener('keydown', this.onPressBar)
  }

  onPressBar = (event) => {
    if (event.key === '/') {
      const element = this.searchInputRef.current
      const input = element.querySelector('input')

      input.focus()
      event.preventDefault()
    }
  }

  closeAlert = () => {
    this.setState({
      alertIsOpen: false,
      alertText: '',
      isShowingCopiedTip: false,
      alertShowActions: true
    })

    this.alertOnOkay = false
    this.alertOnCancel = false
  }

  setAlert = (text, onOkay, onCancel) => {
    this.setState({
      alertIsOpen: true,
      alertText: text
    })

    this.alertOnOkay = () => {
      (onOkay || (() => {}))()
      this.closeAlert()
    }

    if (onCancel) {
      this.alertOnCancel = () => {
        (onCancel || (() => {}))()
        this.closeAlert()
      }
    }
  }

  handleFilterValue = ({ target: { value } }) => {
    this.setState({
      filterValue: value
    })

    if (this.filterTimeout) clearTimeout(this.filterTimeout);

    this.filterTimeout = setTimeout(() => {
      this.setStateForExams(value)

      this.setState({
        exams: [],
        isLoadingExams: true,
        currentPage: 1,
        statusMessage: false
      })
    }, 300)
  }

  enableOrDisableSeeExamButton(accessionNumber) {
    this.setState({
      disabledSeeExamButton: this.state.disabledSeeExamButton
        ? null
        : accessionNumber
    })
  }

  enableOrDisableShareButton(accessionNumber) {
    this.setState({
      disabledShareButton: this.state.disabledShareButton
        ? null
        : accessionNumber
    })
  }

  showExam = (accessionNumber) => () => {

    if (this.state.disabledSeeExamButton) {
      return
    }

    // Desabilita o botão.
    this.enableOrDisableSeeExamButton(accessionNumber)

    axios({
      method: 'get',
      url: `${api.baseUrl}/urltoken/accession_number/${accessionNumber}`,
      headers: {
        Authorization: `Bearer ${this.state.accessToken}`
      }
    })
      .then(({ data: { url } }) => {
        const FIVE_MINUTES = 5 * 60000

        if(window.location.host === 'portal.simdiagnostico.com.br') {
          url = url.replace('view.portal.cerdil.com.br', 'view.portal.simdiagnostico.com.br');
        }
        // Habilita o botão.
        this.enableOrDisableSeeExamButton(accessionNumber)

        // Abre PopUp com visualização do exame.
        this.openPopUp(url)

        // Fecha pop-up após determinado periodo de tempo.
        setTimeout(() => {
          if (this.closePopUp) {
            this.closePopUp()
          }
        }, FIVE_MINUTES)
      })
  }

  onCopyToClipboard = () => {
    this.closeAlert()
    this.setAlert($('alerta copiado'))
  }

  onClickToShare = (url, studyDescription) => {
    this.closeAlert()

    if (window.navigator.share) {
      window.navigator.share({
        title: studyDescription,
        url: url,
      })
    } else {
      this.setAlert($('alerta sem suporte'))
    }
  }

  onShare = (accessionNumber, studyDescription) => async () => {
    if (this.state.disabledShareButton) return

    // Deabilita botão.
    this.enableOrDisableShareButton(accessionNumber)

    const urlTokenUrl = `${api.baseUrl}/urltoken/accession_number/${accessionNumber}`

    try {
      const urlTokenResponse = await axios.get(urlTokenUrl, {
        headers: {
          Authorization: `Bearer ${this.state.accessToken}`
        }
      })

      const getUrlByHost = (url) => {
        if(window.location.host === 'portal.simdiagnostico.com.br') {
          return url.replace('view.portal.cerdil.com.br', 'view.portal.simdiagnostico.com.br');
        } else {
          return url;
        }
      }

      const urlShortenerUrl = `${api.urlShortener}/generate.php?url=${getUrlByHost(urlTokenResponse.data.url)}`

      try {
        const urlShortenerResponse = await axios.get(urlShortenerUrl)
        const url = urlShortenerResponse.data.data.url

        this.setState({
          alertShowActions: false
        })

        this.setAlert(
          <ShareLinkModal
            url={url}
            studyDescription={studyDescription}
            onCopyToClipboard={this.onCopyToClipboard}
            onClickToShare={this.onClickToShare}
          />
        )
      } catch (error) {
        this.setAlert($('alerta falha encurtar'))
        console.error(error)
      }
    } catch (error) {
      this.setAlert('alerta falha obter url')
      console.error(error)
    }

    // Habilita novamente o botão.
    this.enableOrDisableShareButton(accessionNumber)
  }

  logout = () => {
    this.setAlert($('alertLogout'), () => {
      localStorage.removeItem('access_token')
      this.props.history.push('/')
    }, () => {
      // Não faz nada
    })
  }

  seeUserData = () => {
    this.props.history.push('/account')
  }

  setStateForPatientInformations = () => {
    axios({
      method: 'get',
      url: `${api.baseUrl}/pacientes`,
      headers: {
        Authorization: `Bearer ${this.state.accessToken}`
      }
    })
      .then(({ data: patientInformations }) => {
        this.setState({
          patientInformations
        })
      })
      .catch(({ response }) => {

        if (!response) {
          return
        }

        // Se não autorizado.
        if (response.status === 401 || response.status === 500) {

          // Deslogar usuário.
          return localStorage.removeItem('access_token')
        }
      })
  }

  setStateForExams = (search) => {
    const url = `${api.baseUrl}/exames?page=${this.state.currentPage}&per_page=${EXAMS_PER_PAGE}`

    axios({
      method: 'get',
      url: url + (search ? `&study_description=${search}` : ''),
      headers: {
        Authorization: `Bearer ${this.state.accessToken}`
      }
    })
      .then(({ data: { data: exams = [], last_page: lastPage } }) => {
        if (exams.length === 0) {
          this.setState({
            isLoadingExams: false
          })

          if (this.state.currentPage === 1) {

            if (search) {
              this.setState({
                statusMessage: $('alertNaoCorresponde')
              })
            } else {
              this.setState({
                statusMessage: $('alertNaoTemExames')
              })
            }
          }
        }

        this.setState({
          exams: [...this.state.exams, ...exams],
          lastPage
        })

        if (this.state.exams.length < EXAMS_PER_PAGE) {
          this.setState({
            isLoadingExams: false
          })
        }
      })
      .catch(({ response }) => {
        if (!response) {
          return
        }

        // Se não autorizado.
        if (response.status === 401 || response.status === 500) {

          // Deslogar usuário.
          return localStorage.removeItem('access_token')
        }
      })
  }

  checkScroll = () => {
    const element = this.containerRef.current.parentElement
    const canLoadMore = element.scrollTop >= (element.scrollHeight - element.clientHeight)

    this.setState({
      showBackToTopButton: element.scrollTop >= 300
    })

    if (canLoadMore) {
      if (this.state.currentPage < this.state.lastPage) {
        this.setState({
          currentPage: this.state.currentPage + 1
        })

        this.setStateForExams()
      } else {
        this.setState({
          isLoadingExams: false
        })
      }
    }
  }

  backToTop = () => {
    const element = this.containerRef.current.parentElement
    const MS = 1
    let scrollY = element.scrollTop

    const interval = setInterval(() => {
      element.scrollTo(0, scrollY)

      if (scrollY <= 0) {
        clearInterval(interval)
      }

      scrollY -= 100
    }, MS)
  }

  /**
   * @param {string} url Endereço para o servidor de visualização do Vue Motion
   */
  openPopUp = (url) => {

    // Se tiver rodando em desenvolvimento então usará o host
    // "dev-view.portal.cerdil.com.br" para evitar problemas com SSL
    if (process.env.NODE_ENV === 'development') {

      // Corrige subdomínio
      url = url.replace(/view/, 'dev-view')

      // Corrige protocolo (http -> https)
      url = url.replace(/https/, 'http')
    }

    this.setState({
      popUpIsOpened: true,
      popUpUrl: url
    })
  }

  closePopUp = () => {
    this.setState({
      popUpIsOpened: false,
      popUpUrl: ''
    })
  }

  render () {
    return (
      <Fragment>
        <PopUp
          isOpen={this.state.popUpIsOpened}
          url={this.state.popUpUrl}
          onClose={this.closePopUp}
        />

        <Alert
          isOpen={this.state.alertIsOpen}
          handleClose={this.closeAlert}
          handleOkay={() => this.alertOnOkay()}
          handleCancel={this.alertOnCancel && (() => this.alertOnCancel())}
          text={this.state.alertText}
          showActions={this.state.alertShowActions}
        />

        {this.state.isShowingCopiedTip && (
          <CopiedTip>
            Copiado para área de transferência!
          </CopiedTip>
        )}

        {this.state.showBackToTopButton && (
          <BackToTop onClick={this.backToTop}>
            <IconArrowUp />
          </BackToTop>
        )}

        <Content
          ref={this.containerRef}
          isLoading={Object.keys(this.state.patientInformations).length === 0}
        >
          <Header
            name={getFirstName(this.state.patientInformations.nm_pessoa_fisica)}
            logout={this.logout}
            seeUserData={this.seeUserData}
          />

          <SearchContainer ref={this.searchInputRef}>
            <Input
              isSmall
              placeholder={$('inputFiltro')}
              value={this.state.filterValue}
              onChange={this.handleFilterValue}
            />
          </SearchContainer>

          {this.state.exams.map((data) => (
            <ExamsList
              key={`${data.rn}.${data.accession_number}`}
              exameName={data.study_description}
              doctorName={data.referring_physician_last_name}
              local={getLocalById(data.site_id)}
              date={prettyDbDate(data.study_date)}
              hasReport={/SR/.test(data.study_modality)}
              showExam={this.showExam(data.accession_number)}
              onShare={this.onShare(data.accession_number, data.study_description)}
              buttonIsDisabled={this.state.disabledSeeExamButton === data.accession_number}
              shareButtonIsDisabled={this.state.disabledShareButton === data.accession_number}
            />
          ))}

          {/* Spinner para dar feedback do carregamento. */}
          {this.state.isLoadingExams && (
            <Spinner />
          )}

          {/* Mensagem a ser exibida ao usuário quando houver erros. */}
          {this.state.statusMessage && (
            <StatusMessage>
              {this.state.statusMessage}
            </StatusMessage>
          )}
        </Content>
      </Fragment>
    )
  }
}

const ShareLinkModal = ({
  url,
  studyDescription,
  onCopyToClipboard,
  onClickToShare
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(url)}`;

    img.onload = () => setLoading(false);
    img.onerror = () => {
      setLoading(false);
      setError(true);
    };
  }, [url]);

  return (
    <CopyContainer>
      <QRCodeContainer>
        {loading && <Loader />}
        {!error && !loading && (
          <img src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(url)}`} alt="QR Code" />
        )}
        {error && <p>Não foi possível carregar o QR code.</p>}
      </QRCodeContainer>
      <CopyLink>
        {url}
      </CopyLink>
      <ButtonGroup>
        <div>
          <CopyButton onClick={onCopyToClipboard} data-clipboard-text={url}>
            <IconCopy /> {$('botao copiar')}
          </CopyButton>
        </div>
        <div>
          <ShareButton onClick={() => onClickToShare(url, studyDescription)}>
            <IconShareAltSolid /> {$('botao compartilhar')}
          </ShareButton>
        </div>
      </ButtonGroup>
    </CopyContainer>
  );
};

const Loader = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-bottom: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const QRCodeContainer = styled.div`
  text-align: center;
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    display: ${props => (props.loading || props.error ? 'none' : 'block')};
  }

  ${props => props.loading && `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px; /* Mantém a altura consistente com o QR code */
  `}

  ${props => props.error && `
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px; /* Mantém a altura consistente com o QR code */
  `}
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
`
const Spinner = styled.div`
  margin-top: 40px;
  text-align: center;

  ::after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    animation: spin .4s linear infinite;
    border: solid 3px #999;
    border-radius: 100%;
    border-right-color: transparent;
    background-color: transparent;

    @keyframes spin {
      from { transform: rotate(0deg); }
      to { transform: rotate(360deg); }
    }
  }
`


const StatusMessage = styled.p`
  margin-top: 40px;
  text-align: center;
  color: gray;
`
const CopyContainer = styled.div`
  padding: 3px;
  border-radius: 3px;
  background-color: #eee;
`
const CopyLink = styled.div`
  display: block;
  padding: 10px 10px;
  margin-bottom: 10px;
  border: dashed 1px #222;
  border-radius: 3px;
  text-align: center;
  background-color: #eee;

  @media only screen and (min-width: 240px) {
    margin-bottom: 5px;
  }
`
const ButtonGroup = styled.div`
  display: flex;

  div {
    flex: 1;
  }
  div:first-child {
    padding-right: 5px;
  }
  div:last-child {
    padding-left: 5px;
  }

  @media only screen and (min-width: 240px) {
    div:first-child {
      padding-right: 2.5px;
    }
    div:last-child {
      padding-left: 2.5px;
    }

    button {
      font-size: .9em;
      white-space: nowrap;
    }
  }
`
const CopyButton = styled(Clipboard)`
  width: 100%;
  padding: 10px 10px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  outline: none;
  background-color: var(--theme-primary-color);
  color: white;
`
const ShareButton = styled.button`
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
`
const CopiedTip = styled.div`
  position: fixed;
  padding: 15px 30px;
  left: 50%;
  bottom: 70px;
  animation: ShowCopyedTip 300ms linear;
  transform: translateX(-50%);
  border-radius: 5px;
  box-shadow: 0 10px 15px 5px rgba(0, 0, 0, .25);
  white-space: nowrap;
  background-color: white;
  z-index: 999;

  @keyframes ShowCopyedTip {
    from { bottom: -100px; }
    to { bottom: 70px; }
  }
`
const BackToTop = styled.button`
  position: fixed;
  right: calc(15vw + 60px);
  bottom: 60px;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 100%;
  outline: none;
  animation: ease-in 150ms ShowBackToTop;
  background-color: var(--theme-primary-color);
  color: white;
  z-index: 1;

  &:hover { opacity: .9; }
  &:active { opacity: 1; }

  @keyframes ShowBackToTop {
    from { transform: scale(0); }
    to { transform: scale(1); }
  }

  /* Remove a imagem à direita a largura diminuir. */
  @media only screen and (max-width: 1000px) {
    display: none;
  }
`

import React from 'react'
import styled from 'styled-components'

// Estilo base.
import InputContainer from './InputContainer'

export default ({ children, ...props }) => (
    <StyledComponent as="select" {...props}>
        {children}
    </StyledComponent>
)

const StyledComponent = styled(InputContainer)`
    border: solid 1px var(--theme-primary-color-light);
    background-color: white;

    :disabled {
        background-color: #eee;
    }
    :focus {
        border-color: var(--theme-primary-color);
    }
`
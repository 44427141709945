/**
 * Normalizar o nome para a apresentação.
 *
 * @param {string} name Nome completo da pessoa.
 * @returns {string} Primeiro nome com inicial maiúscula.
 * @example
 * getFirstName('FULANO DE TAL')
 * //=> 'Fulano'
 */
export default (name) => {
    if (name) {
        return name
            .replace(/\s.*/, '')
            .replace(/^(.)(.*)$/g, (a, b, c) => {
                return b + c.toLowerCase()
            })
    }
}
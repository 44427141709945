import React, { Component } from 'react'
import styled, { createGlobalStyle } from 'styled-components'

import Form from './Form'
import Header from './Header'
import LanguageConfig from './LanguageConfig'
import Support from './Support'

import Alert from '../../components/Alert'
import { IconGlobe, IconQuestionCircleSolid, IconDoctor } from '../../components/Icons'

import isMobileDevice from 'is-mobile-device'

import i18n from '../../i18n/i18n'
import { DomainEnv } from '../../domainEnv'

const $ = i18n('Login')

const currentYear = (new Date()).getFullYear()

export default class extends Component {

    state = {
        alertIsOpen: false,
        alertText: '',
        languagePageIsOpen: false,
        supportPageIsOpen: false,
    }

    componentWillMount() {
        if (localStorage.getItem('access_token')) {
            this.props.history.push(`/exams`)
        }
    }

    componentDidMount() {
        var deferredPrompt

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            deferredPrompt = e

            const ONE_DAY = 86400000
            const lastPromptTime = Number(window.localStorage.getItem('pwa_prompt_time'))
            const timeNow = (new Date()).getTime()
            const isFirstTime = lastPromptTime === 0
            const lastPromptWasOnDayAgo = (timeNow - lastPromptTime) >= ONE_DAY
            const isInstalled = window.matchMedia('(display-mode: standalone)').matches
            
            if (isMobileDevice && !isInstalled && (isFirstTime || lastPromptWasOnDayAgo)) {
                window.localStorage.setItem('pwa_prompt_time', timeNow)

                // definir texto dos botões
                this.setState({
                    installOkayText: $('alertBtnInstall'),
                    installCancelText: $('alertBtnCancelar'),
                })

                this.setAlert($('alertInstallApp'), () => {
                    deferredPrompt.prompt()

                    deferredPrompt.userChoice.then((choiceResult) => {
                        if (choiceResult.outcome === 'accepted') {
                            console.log('Usuário instalou o app.')
                        } else {
                            console.log('Usuário não quis instalar o app.')
                        }

                        deferredPrompt = null
                    })
                })
            }
        })
    }

    openLanguagePage = () => {
        this.setState({
            languagePageIsOpen: true
        })
    }

    closeLanguagePage = () => {
        this.setState({
            languagePageIsOpen: false
        })
    }

    openSupportPage = () => {
        this.setState({
            supportPageIsOpen: true
        })
    }
 
    closeSupportPage = () => {
        this.setState({
            supportPageIsOpen: false
        })
    }

    closeAlert = () => {
        this.setState({
            alertIsOpen: false,
            alertText: '',
            installOkayText: null,
            installCancelText: null,
        })
    }

    setAlert = (text, onOkay, onCancel) => {
        this.setState({
            alertIsOpen: true,
            alertText: text
        })

        this.alertOnOkay = () => {
            (onOkay || (() => {}))()
            this.closeAlert()
        }
        this.alertOnCancel = () => {
            (onCancel || (() => {}))()
            this.closeAlert()
        }
    }

    render() {
        return (
            <MainContent>
                <GlobalStyles />
                <Alert
                    isOpen={this.state.alertIsOpen}
                    handleClose={this.closeAlert}
                    handleOkay={() => this.alertOnOkay()}
                    handleCancel={() => this.alertOnCancel()}
                    text={this.state.alertText}
                    okayText={this.state.installOkayText}
                    cancelText={this.state.installCancelText}
                />

                <LanguageConfig
                    isOpen={this.state.languagePageIsOpen}
                    onClose={this.closeLanguagePage}
                />
                <Support
                    isOpen={this.state.supportPageIsOpen}
                    onClose={this.closeSupportPage}
                />

                <Header />
                <Form setAlert={this.setAlert} goToPage={this.props.history.push} />
        
                <Actions>
                    <Action>
                        <ActionLink onClick={this.openLanguagePage}>
                            <IconContainer>
                                <IconGlobe />
                            </IconContainer>
                            <ActionTitle>
                                {$('idioma')}
                            </ActionTitle>
                        </ActionLink>
                    </Action>
                    <Action>
                        <ActionLink
                            as='a'
                            href={`${DomainEnv.linkPortal}/Login.aspx`}
                        >
                            <IconContainer>
                                <IconDoctor />
                            </IconContainer>
                            <ActionTitle>
                                {$('portal_medicos')}
                            </ActionTitle>
                        </ActionLink>
                    </Action>
                    <Action>
                        <ActionLink onClick={this.openSupportPage}>
                            <IconContainer>
                                <IconQuestionCircleSolid />
                            </IconContainer>
                            <ActionTitle>
                                {$('suporte')}
                            </ActionTitle>
                        </ActionLink>
                    </Action>
                </Actions>

                <CopyrightMessage>
                    <p>&copy; {currentYear} {DomainEnv.copyrightMessage}</p>
                </CopyrightMessage>
            </MainContent>
        )
    }
}

const GlobalStyles = createGlobalStyle`
    html,
    body,
    #root {
        height: 100%;
        min-height: 550px;
    }
`
const MainContent = styled.main`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 460px;
    height: 85%;
    border-radius: 8px;
    border: solid 1px #ddd;
    overflow: hidden;
    background-color: white;

    @media only screen and (max-width: 500px) {
      width: 100%;
      height: 100%;
      border: initial;
      border-radius: initial;
    }
`
const Actions = styled.div`
    position: relative;
    width: 80%;
    margin: 0 auto;
    padding-top: 25px;
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 60%;
        height: 1px;
        background-color: #ddd;
    }
`
const Action = styled.div`
    flex: 1;
    text-align: center;
    padding: 0 10px;
`
const ActionLink = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #333;
    cursor: pointer;
    text-decoration: none;

    &:hover,
    &:hover * {
        color: black;
    }
    &:active,
    &:active * {
        color: #666;
    }
`
const IconContainer = styled.div`
    font-size: 2.2em;
    width: 40px;
    height: 40px;
    margin-bottom: 10px;
`
const ActionTitle = styled.h2`
    font-size: 1.1em;
`
const CopyrightMessage = styled.footer`
    font-size: 0.85em;
    padding: 12.5px 0;
    border-top: 1px solid #ddd;
    text-align: center;
    background-color: #e9e9e9;
`

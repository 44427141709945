/**
 * Formatar data que vem do banco de dados.
 *
 * @param {string} date Date que vem do banco de dados.
 * @returns {string} Data no formato DD/MM/YYYY.
 * @example
 * dbDateToHuman('1999-09-14 00:00:00')
 * //=> '14/09/1999'
 */
export default (date) => {
    return date
        .replace(/\s\d{2}:\d{2}:\d{2}$/, '')
        .split('-')
        .reverse()
        .join('/')
}
import React from 'react'
import Select from './Select'

// Dados.
import brazilStates from '../data/brazilStates'

export default ({ placeholder, ...props }) => (
    <Select {...props} >
        <option value="" defaultValue disabled hidden>{placeholder}</option>

        {brazilStates.map(({ initials, name }) => (
            <option key={initials} value={initials}>{name}</option>
        ))}
    </Select>
)
/*eslint no-script-url: 0*/
import React from 'react'
import styled from 'styled-components'

// Componentes.
import Button from '../../components/Button'
import { IconMapMarkerAlt, IconCalendarAlt, IconFileAlt } from '../../components/Icons'

// i18n
import i18n from '../../i18n/i18n'

const $ = i18n('Exams')

export default ({
    exameName,
    doctorName,
    local,
    date,
    hasReport,
    showExam,
    onShare,
    buttonIsDisabled = false,
    shareButtonIsDisabled = false,
}) => (
    <Card>
        <ContentLeft>
            <Title>{exameName || <i>({$('textSemTitulo')})</i>}</Title>
            <DoctorName>Dr(a). {doctorName || <i>({$('textNaoEncontrado')})</i>}</DoctorName>
        </ContentLeft>
        <ContentRight>
            <Informations>
                <p><IconMapMarkerAlt /> {local}</p>
                <p><IconCalendarAlt /> {date}</p>
                <p><IconFileAlt /> {hasReport ? $('msgComLaudo') : $('msgSemLaudo') }</p>
            </Informations>
            <Actions>
                <div>
                    <ViewButton
                        isInline
                        isSmall
                        noWrap
                        onClick={showExam}
                        disabled={buttonIsDisabled}
                    >
                        {$('btnVerExames')}
                    </ViewButton>
                    <ShareLink onClick={onShare} href="javascript:void(0);" disabled={shareButtonIsDisabled}>
                        {$('linkGenerateLink')}
                    </ShareLink>
                </div>
            </Actions>
        </ContentRight>
    </Card>
)

const Card = styled.div`
    display: flex;
    padding: 20px 30px;
    border: solid 1px #ddd;
    border-radius: 4px;

    :not(:last-child) {
        margin-bottom: 15px;
    }
    @media only screen and (max-width: 500px) {
        padding: 15px 20px;
        flex-direction: column;

        :not(:last-child) {
            margin-bottom: 10px;
        }
    }
`
const ContentLeft = styled.div`
    min-width: 350px;
    padding-right: 15px;

    @media only screen and (max-width: 500px) {
        min-width: initial;
        max-width: 350px;
        margin-bottom: 15px;
        padding-right: 0;
    }
`
const ContentRight = styled.div`
    display: flex;
    flex: 1;
`
const Title = styled.h2`
    margin-bottom: 5px;
    font-size: 1.2em;
    text-transform: uppercase;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media only screen and (max-width: 500px) {
        white-space: initial;
    }
`
const DoctorName = styled.p`
    color: gray;

    @media only screen and (max-width: 500px) {
        font-size: .8em;
    }

`
const Informations = styled.div`
    width: 190px;
    font-size: 1em;
    line-height: 160%;

    @media only screen and (max-width: 500px) {
        width: initial;
        flex: 1;
    }
`
const Actions = styled.div`
    flex: 1;
    text-align: right;
    margin-left: 10px;

    div {
        text-align: center;
    }

    @media only screen and (max-width: 500px) {
        flex: 0;
    }
`
const ViewButton = styled(Button)`
    margin-bottom: 10px;
    font-size: 0.9em;
`
const ShareLink = styled.a`
    color: var(--theme-primary-color);
    text-decoration: none;

    &[disabled] {
        color: gray;
    }
`
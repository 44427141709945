import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Estilo base.
import InputContainer from './InputContainer'

const RIPPLE_EFFECT_TIME = 500

export default class extends Component {
    state = {
        rippleEffectIsVisible: false
    }

    runRippleEffect = () => {
        this.setState({ rippleEffectIsVisible: true })
        setTimeout(
            () => this.setState({ rippleEffectIsVisible: false }),
            RIPPLE_EFFECT_TIME
        )
    }

    render = () => {
        const { children, ...props } = this.props

        return (
            <StyledComponent
                as="button"
                onClick={this.runRippleEffect}
                {...props}
            >
                <RippleEffect isVisible={this.state.rippleEffectIsVisible} />
                <Label>{children}</Label>
            </StyledComponent>
        )
    }
}

const StyledComponent = styled(InputContainer)`
    position: relative;
    padding: 0;
    overflow: hidden;
    color: white;
    cursor: pointer;
    background-color: var(--theme-primary-color);

    &[disabled] {
        opacity: .75;
    }

    ${props => props.isSecondary && css`
        border: solid 1px var(--theme-primary-color);
        background-color: white;
        color: var(--theme-primary-color);
    `}

    ${props => props.noWrap && css`
        white-space: nowrap;
    `}
`
const RippleEffect = styled.div`
    display: ${props => props.isVisible ? 'block' : 'none'};
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ripple-effect ${RIPPLE_EFFECT_TIME}ms ease-out;
    background-color: #fff;

    @keyframes ripple-effect {
        from {
            width: 0;
            height: 0;
            opacity: .3;
        }
        to {
            width: 300px;
            height: 300px;
            opacity: 0;
        }
    }
`
const Label = styled.span`
    position: relative;
`

export default {

  //
  // Pág. Account
  //
  Account: {
  
    headerTitulo: {
      pt: name => `Olá, ${name}!`,
      es: name => `¡Hola, ${name}!`,
    },
    headerDescricao: {
      pt: `Atualizar os dados da sua conta.`,
      es: `Actualiza los datos de tu cuenta.`,
    },
  
    alertConfirmacao: {
      pt: `Tem certeza?`,
      es: `¿Está seguro?`,
    },
    alertAlturaInvalida: {
      pt: `Altura inválida!`,
      es: `Altura inválida!`,
    },
    alertAlturaNaoPermitida: {
      pt: `Altura não permitida!`,
      es: `Altura no permitida!`,
    },
    alertDadosAtualizados: {
      pt: `Dados atualizados com sucesso!`,
      es: `Datos actualizados con éxito!`,
    },
    alertDadosNaoAtualizados: {
      pt: `Não foi possível salvar os dados!`,
      es: `¡No se pudieron guardar los datos!`,
    },

    btnCancelar: {
      pt: `Cancelar`,
      es: `Cancelar`,
    },
    btnContinuar: {
      pt: `Continuar`,
      es: `Seguir`,
    },

  },

  //
  // Pág. Confirmation
  //
  Confirmation: {

    headerTitulo: {
      pt: name => `Olá, ${name}`,
      es: name => `¡Hola, ${name}!`,
    },
    headerDescricao: {
      pt: `Confirme os dados para criarmos sua conta`,
      es: `Confirma los datos para crear tu cuenta`,
    },

    alertTemCerteza: {
      pt: `Tem certeza?`,
      es: `¿Está seguro?`,
    },

    btnCancelar: {
      pt: `Cancelar`,
      es: `Cancelar`,
    },

    btnProximaEtapa: {
      pt: `Próxima etapa`,
      es: `Paso siguiente`,
    },

  },

  //
  // Pág. Exams
  //
  Exams: {

    alertLogout: {
      pt: `Deseja sair?`,
      es: `¿Cerrar sesión?`,
    },

    alertNaoCorresponde: {
      pt: `Nenhum exame corresponde o filtro`,
      es: `Ningún examen coincide con el filtro`,
    },

    alertNaoTemExames: {
      pt: `Ops! Você não tem exames.`,
      es: `¡Usted no tienes examenes!`,
    },

    'alerta copiado': {
      pt: 'Copiado para a área de transferência!',
      es: '¡Copiado al portapapeles!'
    },

    'alerta sem suporte': {
      pt: 'Você não tem suporte para compartilhar!',
      es: '¡No tienes soporte para compartir!'
    },

    'alerta falha encurtar': {
      pt: 'Não foi possível encurtar o URL!',
      es: '¡No se pudo acortar la URL!'
    },

    'alerta falha obter url': {
      pt: 'Não foi possível obter o URL!',
      es: '¡No se pudo obtener la URL!'
    },

    'botao copiar': {
      pt: 'Copiar',
      es: 'Copiar'
    },

    'botao compartilhar': {
      pt: 'Compartilhar',
      es: 'Compartir'
    },

    inputFiltro: {
      pt: `Filtrar os exames aqui...`,
      es: `Filtra los exámenes aquí ...`,
    },

    textSemTitulo: {
      pt: `SEM TÍTULO`,
      es: `SIN TITULO`,
    },

    textNaoEncontrado: {
      pt: `NÃO ENCONTRADO`,
      es: `NO ENCONTRADO`,
    },

    msgComLaudo: {
      pt: `Com laudo`,
      es: `Con informe`,
    },

    msgSemLaudo: {
      pt: `Sem laudo`,
      es: `Sin informe`,
    },

    btnVerExames: {
      pt: `Ver exame`,
      es: `Ver examen`,
    },

    headerOla: {
      pt: name => `Olá, ${name}`,
      es: name => `¡Hola, ${name}!`,
    },

    btnMeusDados: {
      pt: `Meus dados`,
      es: `Mis datos`,
    },

    btnSair: {
      pt: `Sair`,
      es: `Cerrar sesión`,
    },

    linkGenerateLink: {
      pt: `Gerar link`,
      es: `Generar link`,
    },

  },

  //
  // Pág. Login
  //
  Login: {
  
    // Botões do rodapé
    idioma: {
      pt: `Idioma`,
      es: `Idioma`,
    },
    portal_medicos: {
      pt: `Médicos`,
      es: `Doctores`,
    },
    suporte: {
      pt: `Suporte`,
      es: `Soporte`,
    },

    // Página de edição de idioma
    selecionar_idioma: {
      pt: 'Selecione o idioma do portal.',
      es: 'Seleccione el idioma del portal.',
    },

    // Página de suporte
    fale_whatsapp: {
      pt: 'Fale pelo WhatsApp',
      es: 'Habla en WhatsApp',
    },
    fale_whatsapp_opcao: {
      pt: 'opção 11 do Menu',
      es: 'Opción de menú 11',
    },
    fale_telefone: {
      pt: 'Ou no telefone',
      es: 'O por teléfono',
    },
    chamar_whatsapp: {
      pt: 'Chamar no WhatsApp',
      es: 'Llama a WhatsApp',
    },
    ligar_agora: {
      pt: 'Ligar agora',
      es: 'Chamar agora',
    },

    // PWA
    alertBtnInstall: {
      pt: `Instalar`,
      es: `Instalar`,
    },
    alertBtnCancelar: {
      pt: `Agora não`,
      es: `Ahora no`,
    },
    alertInstallApp: {
      pt: `Deseja instalar o aplicativo?`,
      es: `¿Quieres instalar la aplicación?`,
    },

    // Alerts
    alertCpfInvalido: {
      pt: `Número de CPF inválido!`,
      es: `¡Número de CPF no és válido!`,
    },
    alertSenhaInvalida: {
      pt: `Senha inválida!`,
      es: `¡Contraseña invalida!`,
    },
    alertPacienteNaoEncontrado: {
      pt: `Paciente não encontrado!`,
      es: `¡Paciente no encontrado!`,
    },
    alertErroInterno: {
      pt: `Erro interno!`,
      es: `¡Error interno!`,
    },

    headerDescriptionVoltar: {
      pt: 'Voltar',
      es: 'Volver',
    },
  
    //
    // Login
    //
    headerTitleLogin: {
      pt: `ENTRAR`,
      es: `INICIAR SESIÓN`,
    },
    headerDescriptionLogin: {
      pt: `Olá, vamos ver seus exames?`,
      es: `Hola, vamos a ver tus exámenes?`,
    },
    inputPlaceholderLogin: {
      pt: `Login com CPF ou identificação`,
      es: `Inicie sesión con CPF o ID`,
    },
    btnLogin: {
      pt: `Continuar`,
      es: `Continuar`
    },

    //
    // Password
    //
    headerTitlePassword: {
      pt: `SENHA`,
      es: `CONTRASEÑA`,
    },
    headerDescriptionPassword: {
      pt: `Certo, agora precisamos da senha.`,
      es: `Bien, ahora necesitamos la contraseña.`,
    },
    inputPlaceholderPassword: {
      pt: `Senha definida no cadastro`,
      es: `Contraseña establecida en el registro`,
    },
    btnPassword: {
      pt: `Entrar`,
      es: `Iniciar sesión`,
    },

    //
    // Confirmation
    //
    headerTitleConfirmation: {
      pt: `REGISTRO`,
      es: `INSCRIPCIÓN`,
    },
    headerDescriptionConfirmation: {
      pt: `Informe data de nascimento.`,
      es: `Ingrese fecha de nacimiento`,
    },
    inputPlaceholderConfirmation: {
      pt: `DD-MM-AAAA`,
      es: `DD-MM-AAAA`,
    },
    btnConfirmation: {
      pt: `Iniciar cadastro`,
      es: `Iniciar registro`,
    },

  },

  //
  // Pág. PasswordCreation
  //
  PasswordCreation: {

    alertTemCerteza: {
      pt: `Tem certeza?`,
      es: `¿Está seguro?`,
    },
    alertEscolhaSenha: {
      pt: `Escolha uma senha, por favor.`,
      es: `Elige una contraseña, por favor.`,
    },
    alertSenhasNaoConferem: {
      pt: `As senhas não conferem!`,
      es: `¡Las contraseñas no coinciden!`,
    },
    alertSenhaCurta: {
      pt: `A senha deve ter pelo menos 4 caracteres.`,
      es: `La contraseña debe tener al menos 4 caracteres.`,
    },
    alertAlturaInvalida: {
      pt: `Altura inválida!`,
      es: `Altura inválida!`,
    },
    alertAlturaNaoPermitida: {
      pt: `Altura não permitida!`,
      es: `Altura no permitida!`,
    },
    alertErroAtualizacao: {
      pt: `Erro na atualização dos dados do usuário.`,
      es: `Error al actualizar los datos del usuario.`,
    },
    alertErroNaCriacao: {
      pt: `Erro na criação do usuário.`,
      es: `Error al crear usuario.`,
    },

    headerTitulo: {
      pt: `Segurança`,
      es: `Seguridad`,
    },
    headerDescricao: {
      pt: `Quase lá! Vamos criar uma senha.`,
      es: `¡Casi allí! Vamos a crear una contraseña.`,
    },

    inputNovaSenha: {
      pt: `Escolha uma senha`,
      es: `Elige una contraseña`,
    },
    inputConfirmarSenha: {
      pt: `Confirme a senha escolhida`,
      es: `Confirme su contraseña elegida`,
    },

    btnVoltar: {
      pt: `Voltar`,
      es: `Volver`,
    },
    btnCriarConta: {
      pt: `Criar conta`,
      es: `Crear cuenta`,
    },

  },

  components: {

    carregando: {
      pt: `Carregando...`,
      es: `Cargando...`,
    },

    alertBtnCancelar: {
      pt: 'Cancelar',
      es: 'Cancelar',
    },
    alertBtnOk: {
      pt: 'Ok',
      es: 'Ok',
    },

    nome_completo: {
      pt: `Nome completo`,
      es: `Nombre completo`,
    },
    genero: {
      pt: `Gênero`,
      es: `Género`,
    },
    selecione_genero: {
      pt: `Selecione o gênero`,
      es: `Seleccione género`,
    },
    peso: {
      pt: `Peso`,
      es: `Peso`,
    },
    altura: {
      pt: `Altura`,
      es: `Altura`,
    },
    data_de_nascimento: {
      pt: `Data de nascimento`,
      es: `Fecha de nacimiento`,
    },
    telefone: {
      pt: `Telefone`,
      es: `Telefono`
    },
    bairro: {
      pt: `Bairro`,
      es: `Barrio`,
    },
    endereco: {
      pt: `Endereço`,
      es: `Dirección`,
    },
    numero: {
      pt: `Número`,
      es: `Numero`,
    },
    cidade: {
      pt: `Cidade`,
      es: `Ciudad`,
    },
    estado: {
      pt: `Estado`,
      es: `Departamento`
    },
    selecione_estado: {
      pt: `Selecione um estado`,
      es: `Seleccione un departamento`,
    },

  }

}

import React from 'react'
import styled, { css } from 'styled-components'
import { Animated } from 'react-animated-css'

import { IconWhatsapp, IconPhone } from '../../components/Icons'
import InputContainer from '../../components/InputContainer'

import phoneNumbers from '../../data/phoneNumbers.json'

import i18n from '../../i18n/i18n'

const $ = i18n('Login')

export default ({ isOpen, onClose }) => {
    if (!isOpen) return null

    return (
        <Background
            as={Animated}
            animationIn="zoomIn"
            isVisible={true}
            animationInDuration={250}
        >
            <Container>
                <Header>
                    <Title>
                        {$('suporte').toUpperCase()}
                    </Title>
                </Header>
                <Article>
                    <Text>{$('fale_whatsapp')} <b>{phoneNumbers.whatsapp.support.formatted}</b></Text>
                    <Text>{$('fale_whatsapp_opcao')}</Text>
                    <Text>{$('fale_telefone')} <b>{phoneNumbers.call.formatted}</b></Text>
                </Article>
                <HorizontalBar />
                <AppButtons>
                    <div>
                        <AppButton
                            as='a'
                            isWhatsApp
                            href={`https://api.whatsapp.com/send?1=pt_BR&phone=${phoneNumbers.whatsapp.support.unformatted}`}
                        >
                            <IconWhatsapp /> {$('chamar_whatsapp')}
                        </AppButton>
                    </div>
                    <div>
                        <AppButton
                            as='a'
                            isCall
                            href={`tel:${phoneNumbers.call.unformatted}`}
                        >
                            <IconPhone /> {$('ligar_agora')}
                        </AppButton>
                    </div>
                    <div>
                        <AppButton
                            as='button'
                            onClick={onClose}
                        >
                            Cancelar
                        </AppButton>
                    </div>
                </AppButtons>
            </Container>
        </Background>
    )
}

const Background = styled.div`
    position: absolute;
    top: 0
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 999;
`
const Container = styled.div`
    position: absolute;
    width: 75%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`
const Header = styled.header`
    padding-bottom: 10px;
    text-align: center;
    white-space: nowrap;
`
const Title = styled.h2`
    margin-bottom: 20px;
    font-weight: 400;
    margin-bottom: 7px;
    font-size: 2.6em;
    font-family: 'Roboto Condensed', Roboto, sans-serif;
`
const Article = styled.div`
    margin-bottom: 20px;
`
const Text = styled.p`
    line-height: 1.85;
    white-space: nowrap;

    b {
        font-weight: bold;
    }
`
const HorizontalBar = styled.hr`
    width: 30%;
    height: 1px;
    margin: 0 auto;
    margin-bottom: 20px;
    border: none;
    background-color: #ddd;
`
const AppButtons = styled.div`
    padding-top: 15px;
`
const AppButton = styled(InputContainer)`
    display: inline-block;
    padding: 14px 20px;
    margin-bottom: 8px;
    height: unset;
    text-decoration: none;
    border: solid 1px var(--theme-primary-color);
    color: var(--theme-primary-color);
    background-color: white;
    cursor: pointer;

    ${props => props.isWhatsApp && css`
        --whatsapp-color: #2e913e;
        border-color: var(--whatsapp-color);
        color: var(--whatsapp-color);
    `}
    ${props => props.isCall && css`
        --call-color: #b14523;
        border-color: var(--call-color);
        color: var(--call-color);
    `}
`

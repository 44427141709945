/**
 * Converter centímetros em metros.
 */
export const cm2m = (cm) => {
    return (Number(cm) / 100).toFixed(2)
}

/**
 * Converter metros em centímetros.
 */
export const m2cm = (m) => {
    m = Number(m)
    const cm = m * 100

    return cm.toString()
}

/**
 * Verificar se valor em metros é válido.
 */
export const isValidMeters = (m) => {
    return /^\d+(\.\d+)?$/.test(m)
}
import React, { Component } from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'

/*eslint no-script-url: 0*/
// local components
import Button from '../../components/Button'
import Input from '../../components/Input'
import InputLogin from '../../components/InputLogin'
import InputDate from '../../components/InputDate'
import InputPassword from '../../components/InputPassword'

// icons components
import { IconArrowLeft } from '../../components/Icons'

// i18n
import i18n from '../../i18n/i18n'

const $ = i18n('Login')

function getInputAs(type) {
    return ({
        'login': InputLogin,
        'password': InputPassword,
        'confirmation': InputDate
    })[type]
}

export default class extends Component {
    state = {
        animationVisibility: true,
    }

    containerRef = React.createRef()

    componentWillUpdate(nextProps, nextState) {

        // Executa a animação quando alterar o tipo de formulário.
        // (Técnica: alternar entre `false` e `true`)
        if (this.props.type !== nextProps.type) {
            this.setState({ animationVisibility: false })
            setTimeout(() => this.setState({ animationVisibility: true }), 0)

            // Aproveita a situação para forçar "focus" no componente.
            this.containerRef
                .current
                .querySelector('input')
                .focus()
        }
    }

    render () {
        const {
            onSubmit = () => {},
            type,
            textBig,
            textDescription,
            inputValue,
            inputType = 'text',
            inputPlaceholder,
            inputValueOnChange,
            buttonLabel,
            hasAnimation = true,
            buttonIsDisabled = false,
            onClickToBack
        } = this.props

        return (
            <Container
                ref={this.containerRef}
                paddingTop={this.state.paddingTop}
            >
                <VerticalContainer>
                    <Animated
                        isVisible={this.state.animationVisibility}
                        animationIn="bounceInLeft"
                        animationInDuration={hasAnimation ? 200 : 0}
                        animationOutDuration={0}
                    >
                        <Header withBackButton={!!onClickToBack}>
                            <Title>
                                {textBig}
                            </Title>
                            <p>{textDescription}</p>
                            <p>
                                {!!onClickToBack && (
                                    <BackButton href="javascript:void(0);" onClick={onClickToBack}>
                                        <IconArrowLeft /> {$('headerDescriptionVoltar')}
                                    </BackButton>
                                )}
                            </p>
                        </Header>
                        <Form onSubmit={(event) => {
                            event.preventDefault()
                            onSubmit(event)
                        }}>
                            <InputContainer>
                                <Input
                                    as={getInputAs(type)}
                                    autoFocus
                                    type={inputType}
                                    value={inputValue}
                                    placeholder={inputPlaceholder}
                                    onChange={inputValueOnChange}
                                    hideSpinner
                                />
                            </InputContainer>
                            <Button type="submit" disabled={buttonIsDisabled}>
                                {buttonLabel}
                            </Button>
                        </Form>
                    </Animated>
                </VerticalContainer>
            </Container>
        )
    }
}

const Container = styled.div`
    flex: 1;
    position: relative;
`
const VerticalContainer = styled.div`
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
`
const Form = styled.form`
    padding: 0 50px;

    @media only screen and (max-width: 500px) {
        padding: 0 25px;
    }
`
const Header = styled.header`
    text-align: center;
    padding-bottom: ${props => props.withBackButton ? '15px' : '25px'};
`
const Title = styled.h1`
    font-weight: 400;
    margin-bottom: 7px;
    font-size: 2.6em;
    font-family: 'Roboto Condensed', Roboto, sans-serif;
`
const InputContainer = styled.div`
    margin-bottom: 10px;
`
const BackButton = styled.a`
    display: inline-block;
    padding: 3px 8px;
    margin-top: 15px;
    color: black;
    text-decoration: none;
    border: solid 1px #eee;
    border-radius: 3px;

    &:hover {
        background-color: #eee;
    }
    &:active {
        background-color: #ddd;
    }
`
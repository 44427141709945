import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

// Componentes.
import GlobalStyles from './GlobalStyles'
import App from './App'

// Limpar sessionStorage.
sessionStorage.clear()

ReactDOM.render(
    <Fragment>
        <GlobalStyles />

        <Router>
            <App />
        </Router>
    </Fragment>,
    document.getElementById('root')
)

import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { Animated } from 'react-animated-css'

// Componentes.
import Button from '../components/Button'

// i18n
import i18n from '../i18n/i18n'

const $ = i18n('components')

export default ({
    isOpen,
    handleClose,
    handleOkay,
    handleCancel,
    text,
    showActions = true,
    okayText,
    cancelText,
}) => (
    <Fragment>
        {isOpen && <BackgroundBlocker />}

        <Container
            as={Animated}
            animationIn="fadeIn"
            isVisible={true}
            animationInDuration={200}
            isOpen={isOpen}
        >
            <Header>
                <CloseButton onClick={handleClose}>
                    &times;
                </CloseButton>
            </Header>
            <Content>
                {text}
            </Content>

            {showActions && (
                <Actions>
                    {handleCancel && (
                        <CancelButtonContainer>
                            <Button isSmall isInline isSecondary onClick={handleCancel}>
                                {cancelText || $('alertBtnCancelar')}
                            </Button>
                        </CancelButtonContainer>
                    )}
                    <Button isSmall isInline onClick={handleOkay}>
                        {okayText || $('alertBtnOk')}
                    </Button>
                </Actions>
            )}
        </Container>
    </Fragment>
)

const BackgroundBlocker = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: transparent;
`
const Container = styled.div`
    position: fixed;
    display: none;
    padding: 3px 20px;
    top: 50%;
    left: 20px;
    right: 20px;
    transform: translateY(-50%);
    box-shadow: 0 0 0 100vw rgba(0,0,0,.8);
    border-radius: 3px;
    background-color: white;
    z-index: 999;

    @media only screen and (min-width: 500px) {
        width: 500px;
        left: unset;
        left: 50%;
        right: unset;
        transform: translate(-50%, -50%);
    }

    ${props => props.isOpen && css`
        display: block;
    `}
`
const Header = styled.header`
    padding: 5px 0;
    text-align: right;
    border-bottom: 1px solid #eee;
`
const CloseButton = styled.button`
    font-size: 1.5em;
    font-weight: bold;
    padding: 2px 10px;
    border: none;
    border-radius: 3px;
    color: gray;
    background-color: transparent;
    outline: none;

    :hover {
        opacity: .8;
    }
    :active {
        opacity: .98;
    }
`
const Content = styled.div`
    padding: 20px 0;
`
const Actions = styled.div`
    text-align: right;
    padding-bottom: 15px;
`
const CancelButtonContainer = styled.div`   
    display: inline-block;
    margin-right: 5px;
`
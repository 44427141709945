/**
 * Tranforma todos as propriedades dum objeto em string.
 *
 * objPropsToString({ foo: null, bar: 42 })
 * //=> { foo: '', bar: '42' }
 */
export default (obj) => {
    Object.keys(obj).forEach((key) => {
        obj[key] = obj[key]
            ? obj[key].toString()
            : ''
    })

    return obj
}
import isWebPSupported from 'is-webp-supported'

/**
 * Forçar extensão .webp no arquivo caso o browser tenha suporte.
 *
 * @params {string} filename - Nome do arquivo.
 * @examples
 * webPFile('img1.jpeg'); //=> 'img1.webp', se tiver suporte.
 * webPFile('img2.jpeg'); //=> 'img2.jpeg', se não tiver suporte.
 */
export default (filename) => {
  return isWebPSupported()
    ? addWebPExtension(filename)
    : filename
}

function addWebPExtension(filename) {
  return filename.replace(/^(.*)\.\w+$/, '$1.webp')
}
import React from 'react'
import Select from './Select'

// Dados.
import genders from '../data/genders'

export default ({ placeholder, ...props }) => (
    <Select {...props} >
        <option value="" defaultValue disabled hidden>{placeholder}</option>

        {genders.map(({ initials, name }) => (
            <option key={initials} value={initials}>{name}</option>
        ))}
    </Select>
)

import React, { useState } from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'
import Spinner from '../../components/Spinner'

export default ({
  isOpen,
  url,
  onClose
}) => {
  const [loading, setLoading] = useState(true)

  function handleIframeLoad() {
    setLoading(false)
  }

  return (
    <>
      {isOpen && (
        <Container
          as={Animated}
          animationIn="zoomIn"
          isVisible={true}
          animationInDuration={100}
          isOpen={isOpen}
        >
          <header>
            <CloseButton onClick={onClose}  /> 
          </header>

          {loading && (
            <SpinnerContainer>
              <Spinner width={40} color='var(--theme-primary-color)' label="Carregando imagens..." />
            </SpinnerContainer>
          )}

          <Iframe
            src={url}
            onLoad={handleIframeLoad}

          />
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }

  position: fixed;
  box-shadow: 0 0 0 100vw rgba(0,0,0,.85);
  z-index: 999;
  border-radius: 4px;
  top: 10%;
  right: 5%;
  bottom: 10%;
  left: 5%;
  background-color: white;

  @media only screen and (max-width: 500px) {
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;
  }
`

const SpinnerContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
  border-radius: inherit;
`

const CloseButton = styled.button`
  --button-size: 30px;

  @media only screen and (max-width: 500px) {
    --button-size: 25px;
  }

  ::after {
    content: "×";
  }

  position: absolute;
  width: var(--button-size);
  height: var(--button-size);
  top: calc(var(--button-size) / -2);
  right: calc(var(--button-size) / -2);
  border-radius: 100%;
  border: none;
  font-size: 1.4em;
  color: white;
  cursor: pointer;
  background-color: var(--theme-primary-color);
  outline: none;
  
  &:hover {
    filter: contrast(.8);
  }
  &:active {
    filter: contrast(.5);
  }
`
import styled, { css } from 'styled-components'

export default styled.div`
    padding: 0 20px;
    width: 100%;
    height: 50px;
    box-shadow: 0px 10px 15px rgba(25, 25, 25, .1);
    font-size: 1em;

    /* Borda para manter a mesma altura dos outros inputs. */
    border: solid 1px transparent;
    border-radius: 3px;

    /* Opção para não ter a largura de 100%. */
    ${props => props.isInline && css`
        display: inline-block;
        width: initial;
        padding: 0 20px !important;
    `}

    /**
     * Opção de botão pequeno - usando uma altura exata, pois a tag <select>
     * e os inputs do tipo data não ficam sempre com a mesma altura.
     */
    ${props => props.isSmall && css`
        height: 42.5px;
    `}
`

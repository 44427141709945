import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'

// Componentes.
import Spinner from './Spinner'

import i18n from '../i18n/i18n'

const $ = i18n('components')

export default React.forwardRef(({
    isLoading = false,
    headerTitle,
    headerDescription,

    children
}, ref) => {

    return (
        <Container
            as={Animated}
            animationIn="zoomInLeft"
            isVisible={true}
            animationInDuration={300}
        >
            <SubContainer ref={ref}>
                {isLoading ? (
                    <HorizontalCenter>
                        <Spinner width={30} label={$('carregando')} color="#444" />
                    </HorizontalCenter>
                ) : (
                    <Fragment>
                        {(headerTitle && headerDescription) && (
                            <Header>
                                <Title>{headerTitle}</Title>
                                <Description>{headerDescription}</Description>

                                <HorizontalBar />
                            </Header>
                        )}

                        {children}
                    </Fragment>
                )}
            </SubContainer>
        </Container>
    )
})

const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`
const SubContainer = styled.div`
    margin: 75px auto 100px auto;
    padding: 50px 40px 60px 40px;
    width: 800px;
    border-radius: 8px;
    border: solid 1px #ddd;

    @media only screen and (max-width: 500px) {
        margin: 0;
        padding: 30px 20px;
        width: 100%;
        border-radius: 0;
        border: none;
    }
`
const Header = styled.header`
    text-align: center;
    margin-bottom: 30px;

    @media only screen and (max-width: 500px) {
        margin-bottom: 20px;
    }
`
const Title = styled.h1`
    font-size: 2.5em;
    margin-bottom: 15px;

    @media only screen and (max-width: 500px) {
        font-size: 2em;
        margin-bottom: 10px;
    }
`
const Description = styled.p`
    margin-bottom: 25px;
    font-size: 1em;
    color: gray;
    line-height: 175%;
`
const HorizontalBar = styled.hr`
    display: block;
    margin: 0 auto;
    width: 200px;
    height: 1px;
    border: none;
    box-shadow: inset 45px 0px 15px -15px white, inset -45px 0px 15px -15px white;
    background-color: #ddd;
`
const HorizontalCenter = styled.div`
    text-align: center
`

import abbreviatedMonths from '../data/abbreviatedMonths.json'

/**
 * Humanizar o formato da data.
 * 
 * @param {string} date Uma data no formato 'AAAA-MM-DD HH:MM:SS.
 * @return {string} Data num formato mais legível.
 * @example
 * prettyDbDate('1999-09-14 00:00:00')
 * //=> '14 set. 1999'
 */
export default (date) => {
    const dates = date
        .replace(/\s.*/, '')
        .replace(/-/g, '/')
        .split('/')
        .reverse()

    dates[1] = abbreviatedMonths[parseInt(dates[1]) - 1]

    return dates.join(' ')
}
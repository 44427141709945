import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import { remove as removeDiacritics } from 'diacritics'

// Componentes.
import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Content from '../../components/Content'
import UserDataForm from '../../components/UserDataForm'

// Utilitários.
import getFirstName from '../../utils/getFirstName'

// i18n
import i18n from '../../i18n/i18n'

const $ = i18n('Confirmation')

export default class extends Component {

    state = {

        // Dados do usuário no mesmo formato que vem do banco de dados.
        data: JSON.parse(window.sessionStorage.getItem('_userdata')) || {},
        alertIsOpen: false,
        alertText: '',
        disableButton: false
    }

    alertOnOkay = false
    alertOnCancel = false

    componentWillMount() {

        // Para se o usuário acessar a rota '/confirmation' diretamente sem
        // antes passar pela passagem do login e data de nascimento.
        if (Object.keys(this.state.data).length === 0) {
            this.props.history.push('/')
        }

        this.setState({
            userName: getFirstName(this.state.data.nm_pessoa_fisica || '')
        })
    }

    closeAlert = () => {
        this.setState({
            alertIsOpen: false,
            alertText: ''
        })

        this.alertOnOkay = false
        this.alertOnCancel = false
    }

    setAlert = (text, onOkay, onCancel) => {
        this.setState({
            alertIsOpen: true,
            alertText: text
        })

        this.alertOnOkay = () => {
            (onOkay || (() => {}))()
            this.closeAlert()
        }

        if (onCancel) {
            this.alertOnCancel = () => {
                (onCancel || (() => {}))()
                this.closeAlert()
            }
        } 
    }

    handleInputChange = (key) => {
        return ({ target: { value } }) => {
            this.setState({
                data: {
                    ...this.state.data,
                    [key]: removeDiacritics(value).toUpperCase()
                }
            })
        }
    }

    onCancel = () => {
        this.setAlert($('alertTemCerteza'), () => {
            this.props.history.push('/')
        }, () => {
            // Não faz nada
        })
    }

    onContinue = () => {
        this.setState({
            disableButton: true
        })

        window.sessionStorage.clear()
        window.sessionStorage.setItem('_userdata', JSON.stringify(this.state.data))

        this.props.history.push('/password')
    }

    render = () => {
        return (
            <Fragment>
                <Alert
                    isOpen={this.state.alertIsOpen}
                    handleClose={this.closeAlert}
                    handleOkay={() => this.alertOnOkay()}
                    handleCancel={this.alertOnCancel && (() => this.alertOnCancel())}
                    text={this.state.alertText}
                />

                <Content
                    headerTitle={$('headerTitulo')(this.state.userName)}
                    headerDescription={$('headerDescricao')}
                    isLoading={Object.keys(this.state.data).length === 0}
                >
                    <UserDataForm
                        onSubmit={this.onContinue}
                        values={this.state.data}
                        handleInputChange={this.handleInputChange}
                    />

                    <Actions>
                        <div>
                            <Button isInline isSecondary onClick={this.onCancel}>
                                {$('btnCancelar')}
                            </Button>
                        </div>
                        <div>
                            <Button isInline onClick={this.onContinue} disabled={this.state.disableButton}>
                                {$('btnProximaEtapa')} <i className="fas fa-arrow-right" />
                            </Button>
                        </div>
                    </Actions>
                </Content>
            </Fragment>
        )
    }
}

const Actions = styled.div`
    display: flex;
    padding-top: 15px;
    justify-content: space-between;

    @media only screen and (max-width: 500px) {
        padding-top: 0;
    }
`
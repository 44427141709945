import React, { useState } from 'react'

// components
import Input from './Input'

// utilitários
import cpf from 'cpf'

function getDigits(value) {
    if (!value) return ''
    if (typeof value !== 'string') return ''

    return value.replace(/\D/g, '')
}

function isCpf(value) {
    if (!value) return false
    if (typeof value !== 'string') return false

    var ID_LENGTH = 8

    var startLikeCpf = /^\d{3}\./.test(value)
    var endLikeCpf = getDigits(value).length > ID_LENGTH

    return startLikeCpf || endLikeCpf
}

function formatCpf(value) {
    if (!value) return ''
    if (typeof value !== 'string') return ''

    value = getDigits(value)

    var CPF_RE = /^(\d{1,3})(\d{1,3})?(\d{1,3})?(\d{1,2})?/
    var [ , d1='', d2='', d3='', dv='' ] = value.match(CPF_RE)

    var formatedCpf = ''

    formatedCpf += d1.length === 3 ? `${d1}.` : d1
    formatedCpf += d2.length === 3 ? `${d2}.` : d2
    formatedCpf += d3.length === 3 ? `${d3}-` : d3

    formatedCpf += dv

    return formatedCpf
}

function formatLogin(loginValue) {
    return isCpf(loginValue)
        ? formatCpf(loginValue)
        : getDigits(loginValue)
}

export default function ({ ...props }) {
    const [ isValid, changeFieldValidation ] = useState(true)

    const onChange = (event) => {
        if (getDigits(event.target.value).length >= 11) {
            let cpfValue = getDigits(event.target.value)
                cpfValue = cpfValue.replace(/^(.{11}).*$/, '$1')

            changeFieldValidation(cpf.isValid(cpfValue))
        } else {
            changeFieldValidation(true)
        }

        if (event.target.value.length < props.value.length) {
            props.onChange(event)
            return
        }

        event.target.value = formatLogin(event.target.value)
        props.onChange(event)        
    }

    return (
        <Input
            {...props}
            type="text"
            onChange={onChange}
            inputMode="numeric"
            isValid={isValid}
        />
    )
}
import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Componentes.
import { IconEye, IconEyeSlash } from './Icons'
import InputContainer from './InputContainer'

export default class extends Component {
    state = {
        passwordIsVisible: false
    }

    toggleVisibility = ({ target: { checked } }) => {
        this.setState({
            passwordIsVisible: checked
        })
    }

    render = () => {
        const { props } = this
        const { passwordIsVisible } = this.state
        const inputType = passwordIsVisible ? 'text' : 'password'
        const Icon = () => passwordIsVisible ? <IconEyeSlash /> : <IconEye />

        return (
            <Container
                isInline={props.isInline}
                isSmall={props.isSmall}
            >
                <Input {...props} type={inputType} />

                <ChechboxContainer isChecked={passwordIsVisible}>
                    <IconContainer>
                        <Icon />
                    </IconContainer>
                    
                    <CheckboxInput
                        type="checkbox"
                        onChange={this.toggleVisibility}
                        value={passwordIsVisible}
                    />
                </ChechboxContainer>
            </Container>
        )
    }
}

const Container = styled(InputContainer)`
    display: flex;
    padding: 0;
    border: none;
`
const Input = styled.input`
    padding: 0 20px;
    flex: 1;
    font-size: 1em;

    border: solid 1px var(--theme-primary-color-light);
    border-right: none;
    border-radius: 0;
    border-top-left-radius: inherit;
    border-bottom-left-radius: inherit;

    transition: .1s ease-in all;
    outline: none;
    background-color: white;
    
    :disabled {
        background-color: #eee;
    }
    :focus {
        border-color: var(--theme-primary-color);

        & + label {
            border-color: var(--theme-primary-color);
        }
    }
`
const IconContainer = styled.span`
    width: 1em;
    height: 1em;
    position: relative;

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`
const ChechboxContainer = styled.label`
    display: flex;
    padding: 0 20px;
    font-size: 1em;
    align-items: center;

    border: solid 1px #9fb2ea;
    border-top-right-radius: inherit;
    border-bottom-right-radius: inherit;

    transition: .1s ease-in all;
    cursor: pointer;
    color: var(--theme-primary-color);

    ${props => props.isChecked && css`
        border-color: var(--theme-primary-color);
        color: white;
        background-color: var(--theme-primary-color);
    `}
`
const CheckboxInput = styled.input`
    display: none;
`
export const CERDIL_THEME  = {
    themePrimaryColor: '#213b88',
    themePrimaryColorLight: '#9fb2ea',
    themeHighlyLightGray: '#fbfbfb',
    logo: 'cerdil-logo-inline.svg',
    colorBannerRightLogo: 'rgb(25, 55, 123)',
    logoBanner: 'cerdil-banner-logo.svg',
    copyrightMessage: 'Cerdil - Todos os direitos reservados.',
    linkPortal: 'http://portalmedico.cerdil.com.br'
}

export const SIM_THEME  = {
    themePrimaryColor: '#009547',
    themePrimaryColorLight: '#e5fff1',
    themeHighlyLightGray: '#fbfbfb',
    logo: 'sim-logo-inline.svg',
    colorBannerRightLogo: 'rgb(6 96 49)',
    logoBanner: 'sim-banner-logo.svg',
    copyrightMessage: 'Sim Diagnostico - Todos os direitos reservados.',
    linkPortal: 'http://portalmedico.simdiagnostico.com.br'
}

export const DomainEnv  = window.location.host === `portal.simdiagnostico.com.br` ? SIM_THEME : CERDIL_THEME;
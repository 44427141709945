import React, { Component } from 'react'
import styled from 'styled-components'
import { Animated } from 'react-animated-css'

// Componentes.
import { IconCheckSolid } from '../../components/Icons'
import Button from '../../components/Button'

import i18n from '../../i18n/i18n'

const $ = i18n('Login')

export default class extends Component {
    state = {
        currentLanguage: window.localStorage.getItem('lang'),
        languages: {
            'pt': 'Português (pt)',
            'es': 'Español (es)'
        }
    }

    onChangeLanguage = (langCode) => {
        return () => {
            window.localStorage.setItem('lang', langCode)
            window.location.reload()
        }
    }

    render = () => {
        if (!this.props.isOpen) return null

        return (
            <Background
                as={Animated}
                animationIn="zoomIn"
                isVisible={true}
                animationInDuration={250}
            >
                <Container>
                    <Header>
                        <Title>
                            {$('idioma').toUpperCase()}
                        </Title>
                        <Description>
                            {$('selecionar_idioma')}
                        </Description>
                    </Header>
                    <Options>
                        {Object.keys(this.state.languages).map((langCode) => (
                            <Option onClick={this.onChangeLanguage(langCode)} key={langCode}>
                                <OptionText>
                                    {this.state.languages[langCode]}
                                </OptionText>
                                {this.state.currentLanguage === langCode && (
                                    <OptionIcon>
                                        <IconCheckSolid />
                                    </OptionIcon>
                                )}
                            </Option>
                        ))}
                    </Options>
                    <Button isSecondary onClick={this.props.onClose}>
                        Cancelar
                    </Button>
                </Container>
            </Background>
        )
    }
}

const Background = styled.div`
    position: absolute;
    top: 0
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    z-index: 999;
`
const Container = styled.div`
    position: absolute;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
`
const Header = styled.header`
    padding-bottom: 30px;
    text-align: center;
    white-space: nowrap;
`
const Title = styled.h2`
    margin-bottom: 10px;
    font-weight: 400;
    margin-bottom: 7px;
    font-size: 2.6em;
    font-family: 'Roboto Condensed', Roboto, sans-serif;
`
const Description = styled.p`
    color: gray;
`
const Options = styled.div`
    margin-bottom: 20px;
    text-align: left;
`
const Option = styled.div`
    display: flex;
    padding: 15px 20px;
    font-size: 1.15em;
    border: solid 1px #ddd;
    cursor: pointer;

    &:hover {
        background-color: #f5f5f5;
    }
    &:active {
        background-color: #eee;
    }
    &:not(:last-child) {
        border-bottom: none;
    }
`
const OptionText = styled.div`
    flex: 1;
`
const OptionIcon = styled.div`
    color: #4caf50;
`
import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import api from '../../api'

// Componentes.
import Alert from '../../components/Alert'
import Content from '../../components/Content'
import InputPassword from '../../components/InputPassword'
import Button from '../../components/Button'

// i18n
import i18n from '../../i18n/i18n'

const $ = i18n('PasswordCreation')

export default class extends Component {
    state = {
        passwordValue: '',
        passwordConfirmationValue: '',

        alertIsOpen: false,
        alertText: '',

        // Dados do paciente.
        data: JSON.parse(window.sessionStorage.getItem('_userdata')) || {},

        disableButton: false
    }

    componentWillMount = () => {
        if (Object.keys(this.state.data).length === 0) {
            this.props.history.push('/')
        }
    }

    alertOnOkay = false
    alertOnCancel = false
 
    closeAlert = () => {
        this.setState({
            alertIsOpen: false,
            alertText: ''
        })

        this.alertOnOkay = false
        this.alertOnCancel = false
    }

    setAlert = (text, onOkay, onCancel) => {
        this.setState({
            alertIsOpen: true,
            alertText: text
        })

        this.alertOnOkay = () => {
            (onOkay || (() => {}))()
            this.closeAlert()
        }

        if (onCancel) {
            this.alertOnCancel = () => {
                (onCancel || (() => {}))()
                this.closeAlert()
            }
        } 
    }

    goBack = () => {
        this.setAlert($('alertTemCerteza'), () => {
            this.props.history.push('/confirmation')
        }, () => {
            // Não faz nada
        })
    }

    enableButton = () => {
        this.setState({
            disableButton: false
        })
    }

    disableButton = () => {
        this.setState({
            disableButton: true
        })
    }

    createAccount = () => {
        this.disableButton()

        const patientData = this.state.data

        if (!this.state.passwordValue) {
            this.enableButton()
            return this.setAlert($('alertEscolhaSenha'))
        }
        if (this.state.passwordValue !== this.state.passwordConfirmationValue) {
            this.enableButton()
            return this.setAlert($('alertSenhasNaoConferem'))
        }
        if (this.state.passwordValue.length < 4) {
            this.enableButton()
            return this.setAlert($('alertSenhaCurta'))
        }

        const patientId = patientData.cd_pessoa_fisica
        const cpf = patientData.nr_cpf
        const password = this.state.passwordValue

        // Criar usuário.
        axios({
            method: 'POST',
            url: `${api.baseUrl}/usuarios`,
            data: {
                "patient_id": patientId,
                "cpf": cpf,
                "password": password
            }
        })
            .then(({ data: { access_token: accessToken } }) => {
                window.sessionStorage.clear()
                window.localStorage.removeItem('access_token')
                window.localStorage.setItem('access_token', accessToken)

                setTimeout(() => {
                    this.props.history.push(`/exams`)
                }, 0)
            })
            .catch((data) => {
                this.setAlert($('alertErroNaCriacao'))
                this.enableButton()
            })
    }

    onChangeInput = (inputType) => ({ target: { value } }) => {
        this.setState({
            [inputType]: value
        })
    }

    render = () => (
        <Fragment>
            <Alert
                isOpen={this.state.alertIsOpen}
                handleClose={this.closeAlert}
                handleOkay={() => this.alertOnOkay()}
                handleCancel={this.alertOnCancel && (() => this.alertOnCancel())}
                text={this.state.alertText}
            />

            <Content
                headerTitle={$('headerTitulo')}
                headerDescription={$('headerDescricao')}
            >
                <Form>
                    <Field>
                        <InputPassword
                            placeholder={$('inputNovaSenha')}
                            value={this.passwordValue}
                            onChange={this.onChangeInput('passwordValue')}
                        />
                    </Field>
                    <Field>
                        <InputPassword
                            placeholder={$('inputConfirmarSenha')}
                            value={this.passwordConfirmationValue}
                            onChange={this.onChangeInput('passwordConfirmationValue')}
                        />
                    </Field>
                </Form>

                <Actions>
                    <div>
                        <Button isInline isSecondary onClick={this.goBack}>
                            {$('btnVoltar')}
                        </Button>
                    </div>
                    <div>
                        <Button isInline onClick={this.createAccount} disabled={this.state.disableButton}>
                            {$('btnCriarConta')}
                        </Button>
                    </div>
                </Actions>
            </Content>
        </Fragment>
    )
}

const Form = styled.form`
    padding-bottom: 30px;
    border-bottom: solid 1px #eee;
`
const Field = styled.div`
    :not(:last-child) {
        margin-bottom: 15px;
    }
`
const Actions = styled.div`
    display: flex;
    padding-top: 15px;
    justify-content: space-between;
`
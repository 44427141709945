import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { Route, Switch, withRouter } from 'react-router-dom'

// Páginas.
import Account from './pages/Account/Account'
import Confirmation from './pages/Confirmation/Confirmation'
import Exams from './pages/Exams/Exams'
import Login from './pages/Login/Login'
import PasswordCreation from './pages/PasswordCreation/PasswordCreation'

// Utilitários.
import webPFile from './utils/webPFile'
import { DomainEnv } from './domainEnv'

export default withRouter(class extends Component {
    state = {

        // Para a eixibição do logo no canto direito, que só aparecerá nas páginas de login.
        imageIsSmall: this.props.location.pathname !== '/',

        patientData: {}
    }

    componentDidUpdate(prevProps, prevState) {
        const currentLocationWasUpdated = this.props.location.pathname !== prevProps.location.pathname
        const isOnLoginPage = this.props.location.pathname === '/'

        // Remove imagem do lado direito quando tiver na página de login.
        if (currentLocationWasUpdated) {
            if (!isOnLoginPage) {
                this.setState({
                    imageIsSmall: true
                })
            }
        }

        // Ceritifica-se de que a logo vai voltar quando o usuário tiver na página de login.
        if (isOnLoginPage && prevState.imageIsSmall) {
            this.setState({
                imageIsSmall: false
            })
        }
    }

    render = () => (
        <Container>
            <ContainerWithContent>
                <Switch>
                    <Route path="/" exact component={Login} />
                    <Route path="/confirmation" exact component={Confirmation} />
                    <Route path="/password" exact component={PasswordCreation} />
                    <Route path="/account" exact component={Account} />
                    <Route path="/exams" exact component={Exams} />
                </Switch>
            </ContainerWithContent>

            <ContainerWithImage isSmall={this.state.imageIsSmall} />
        </Container>
    )
})

const Container = styled.div`
    display: flex;
    height: inherit;
    justify-content: flex-end;
    background-color: var(--theme-highly-light-gray);
`
const ContainerWithContent = styled.div`
    display: flex;
    flex: 1;
    height: inherit;
    align-items: center;
    justify-content: center;
`
const ContainerWithImage = styled.div`
    position: relative;
    width: 50vw;
    height: inherit;
    transition: all 200ms ease-out;
    background-image: url(/${webPFile('background.jpeg')});
    background-size: cover;
    background-position: 50%;

    /* Diminui a largura da imagem quando não tiver na página de login. */
    ${props => props.isSmall && css`
        width: 15vw;
        background-position: 65%;
    `}

    ::before {
        content: '';
        position: absolute;
        width: 25vw;
        height: 25vw;
        top: 50%;
        left: 50%;
        z-index: 1;
        transform: translate(-50%, -50%);
        background-size: 100%;
        background-image: url(${DomainEnv.logoBanner});

        /* Remove a logo quando a imagem à direita não for pequena.  */
        ${props => props.isSmall && css`
            display: none;
        `}
    }
    ::after {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: .8;
        background-color: ${DomainEnv.colorBannerRightLogo};
    }

    /* Remove a imagem à direita a largura diminuir. */
    @media only screen and (max-width: 1000px) {
        display: none;
    }
`
